import { featureCollections, geoJSON } from './geoJSON.model';
import { BaseMap } from './base-map.model';
import { Bookmark } from './bookmark.model';
export interface UIModel {
  isTripLoading: boolean;
  isBaseMapLoading: boolean;
  isBookmarkLoading: boolean;
  isLogoutLoading: boolean;
  isDrawingMode: boolean;
  isSideBarVisible: boolean;
  isSideBarOpen: boolean;
  isGeometryLoading: boolean;
  isUploadingFile: boolean;

  isTripSaving: boolean;
  isSplitTrackMode: boolean;

  homeBookmark: Bookmark | null;
  mapSource: BaseMap | null;
  isChangesSaved: boolean;
  canAccessUploadTrek: boolean;
  canAccessBookmarks: boolean;
  canAccessHome: boolean;
  canAccessPrint: boolean;
  canAccessDraw: boolean;
  canAccessBasemaps: boolean;
  tripHasLoaded: boolean;
  sidebarMenus: SidebarMenuModel;

  activeTrip: {geoJSON:geoJSON | null, featureCollections: featureCollections} | null;
  isCardOpen: boolean;

}

export interface SidebarMenuModel{
  isMyTripOpen: boolean;
  isDrawOptionsOpen: boolean;
  isTripOptionsOpen: boolean;
}

export const UI = {
  ISTRIPSAVING: 'isTripSaving',
  ISTRIPLOADING: 'isTripLoading',
  ISBASEMAPLOADING: 'isBaseMapLoading',
  ISBOOKMARKLOADING: 'isBookmarkLoading',
  ISLOGOUTLOADING: 'isLogoutLoading',
  ISDRAWINGMODE: 'isDrawingMode',
  ISUPLOADINGFILE: 'isUploadingFile',

  ISSIDEBARVISIBLE: 'isSideBarVisible',
  ISSIDEBAROPEN: 'isSideBarOpen',

  ISSPLITTRACKMODE: 'isSplitTrackMode',

  ISGEOMETRYLOADING: 'isGeometryLoading',

  ISMYTRIPOPEN: 'isMyTripOpen',
  ISDRAWOPTIONSOPEN: 'isDrawOptionsOpen',
  ISMYTRIPOPTIONSOPEN: 'isTripOptionsOpen',

  CANACCESSBOOKMARKS: 'canAccessBookmarks',
  CANACCESSUPLOADTREK: 'canAccessUploadTrek',
  CANACCESSHOME: 'canAccessHome',
  CANACCESSPRINT: 'canAccessPrint',
  CANACCESSDRAW: 'canAccessDraw',
  CANACCESSBASEMAPS: 'canAccessBasemaps',
  TRIPHASLOADED: 'tripHasLoaded',
  ISCARDOPEN: 'isCardOpen',
} as const;

export type UIType = 'isSplitTrackMode' | 'isTripOptionsOpen' |
'isMyTripOpen' | 'isDrawOptionsOpen'|
 'isTripLoading' | 'isBaseMapLoading' |
'isBookmarkLoading' | 'isLogoutLoading' |
'isDrawingMode' | 'isSideBarVisible' |
'isSideBarOpen' | 'canAccessBookmarks'|
'canAccessUploadTrek' | 'canAccessHome' |
'canAccessPrint' | 'canAccessDraw' |
'canAccessBasemaps' | 'tripHasLoaded' | 'isGeometryLoading' | 'isTripSaving' | 'isCardOpen' | 'isUploadingFile'

