import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'homeFirst'
})
export class HomeFirstPipe implements PipeTransform {

  transform(value: any[]): any[] {
    if(value === null){
      return []
    }else{
      const newValue = [...value];
      const homeIndex = value.findIndex(o => o.isHome === true);
      if (homeIndex !== -1) {
        const home = newValue[homeIndex];
        newValue.splice(homeIndex, 1);
        newValue.splice(0, 0, home);
      }
      return newValue;
    }
  }

}
