import { Component, HostBinding } from '@angular/core';

@Component({
  selector: 'app-downloads',
  templateUrl: './downloads.component.html',
  styleUrls: ['./downloads.component.scss']
})
export class DownloadsComponent {
  @HostBinding('style.bottom')
  bottom: string = '0px';

  isExpanded: boolean = true;

  collapse(){
    this.isExpanded = !this.isExpanded;
    this.bottom = this.isExpanded ? '0px': '-80px';
  }
}
