import { Router } from '@angular/router';
import { UI } from './../shared/models/ui.model';
import { UIState } from './../store/state/ui.state';
import { UserState } from './../store/state/user.state';
import { AuthState } from './../store/state/auth.state';
import { User } from './../shared/models/user.model';
import { map, tap, exhaustMap } from 'rxjs/operators';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { Component, HostListener, OnDestroy, OnInit, ViewChild, ElementRef } from '@angular/core';
import { iif, Observable, of, Subscription } from 'rxjs';
import { Select, Store } from '@ngxs/store';
import { UIActions } from '../store/actions/ui.actions';
import { AuthActions } from '../store/actions/auth.actions';
import { ActiveTrip } from '../shared/models/geoJSON.model';
import { MatMenuTrigger } from '@angular/material/menu';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [
    trigger('dropDownState',[
      state('closed',style({
        transform: 'translateY(-150px)'
      })),
      state('opened', style({
        display:'flex',
        transform: 'translateY(0)'
      })),
      transition('closed => opened', animate(250)),
      transition('opened => closed', animate(250)),
    ])
  ]
})
export class HeaderComponent implements OnInit, OnDestroy {
  userProfileTrigger!: MatMenuTrigger;
  dropDownMenu!: ElementRef;
  @ViewChild('userProfileTrigger',{ static: false }) set content(content: MatMenuTrigger){
    if(content){
      this.userProfileTrigger = content;
    }
  }
  @ViewChild('dropDownMenu', {static: false}) set dropDown(dropDown: ElementRef){
    if(dropDown){
      this.dropDownMenu = dropDown;
    }
  }
  @Select(UserState.user) user$!: Observable<User>
  @Select(AuthState.getAccessToken) accessToken$!: Observable<string>;
  @Select(UIState.isLogoutLoading) isLogoutLoading$!: Observable<boolean>;
  @Select(UIState.getActiveTrip) activeTrip$!: Observable<ActiveTrip | null>;

  isLoading: boolean = false;
  state: string = 'closed';
  tokenSubscription!: Subscription;
  logoutSubscription!: Subscription;
  isAuthenticated: boolean = false;
  userSubscription!: Subscription;
  user!: User;

  constructor(private store: Store, private router: Router) {
    this.tokenSubscription = this.accessToken$
      .pipe(
        map((token) => { return !!token }),
        tap((token) => {
          this.isAuthenticated = token;
        }),
        exhaustMap((token)=>{
          return iif(() => token === true, this.user$,of(false))
        }),
        map((result) => { return !!result})
      ).subscribe((result)=>{
        if(result){
          this.userSubscription = this.user$.subscribe((user: User) => {
            this.user = user;
          });
        }else{
        }
      });

   }

  ngOnInit(): void {
  }

  ngOnDestroy(){
    this.tokenSubscription.unsubscribe();
    if(this.logoutSubscription){
      this.logoutSubscription.unsubscribe();
    }
    if(this.userSubscription){
      this.userSubscription.unsubscribe();
    }
  }

  navigateToHelp(): void{
    window.location.href = 'https://bcnavxe.freshdesk.com/support/home'
  }

  @HostListener('window:resize', ['$event'])
  onResize(evt: any) {
    this.closeMenus();
  }

  closeMenus(){
    if (window.innerWidth >= 768) {
      this.state = 'closed';
    }else{
      this.closeMenu();
    }
  }

  closeResponsiveMenu(){
    if (window.innerWidth >= 768) {
      this.closeMenu();
    }else{
      this.state = 'closed';
    }
  }

  dropDownTrigger(): void{
   this.state = (this.state == 'closed' ? 'opened' : 'closed');
  }

  onLogout(){
    this.closeResponsiveMenu();
    this.store.dispatch([
      new UIActions.SetUI(UI.ISLOGOUTLOADING,true),
      new AuthActions.Logout()])
  }

  navigate(url: string, query: string | null = null,fragment: string | null = null){
    this.router.navigateByUrl(url+(query? '/' + query : '') + (fragment? '#' + fragment: ''));
    this.dropDownTrigger();
  }

  closeMenu(){
    if(this.userProfileTrigger){
      this.userProfileTrigger.closeMenu();
    }
  }

}
