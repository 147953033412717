import { addressInterface } from './../constants/map';
import { MapTypeInterface } from './../models/map-type.model';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterAddOn'
})
export class FilterAddOnPipe implements PipeTransform {

  transform(value: any[], filterString: string,
    publicLand: boolean,
    boundary: boolean,
    gameManagement: boolean,
    lake: boolean,
    trailMaps: boolean,
    parcel: boolean,
    address: addressInterface | null
    ): any[] {
    if(!value){
      return value;
    }


    const resultArray: any[] = [];

    for (const item of value){
      if (item['shortname'].toLowerCase().includes(filterString.toLowerCase()) || item['name'].toLowerCase().includes(filterString.toLowerCase())){
        resultArray.push(item);
      }
    }

    //location filter
    const filterLocationArray = resultArray.filter((element)=>{
      if(element.location.ww === 'ww'){
        return element;
      }else if(element.location.country.includes(address?.country.toUpperCase()) && address?.country !== ''){
        return element;
      }else if(element.location.state.includes(address?.state.toUpperCase())  && address?.state !== ''){
        return element;
      }
  })


   return filterLocationArray.filter((element)=>{
      if(publicLand){
        if (element.mapsubject.includes('Public Land')){
          return element
        }
      }
      if (boundary) {
        if (element.mapsubject.includes('Boundary')) {
          return element
        }
      }
      if (gameManagement) {
        if (element.mapsubject.includes('Game Management')) {
          return element
        }
      }
      if (lake) {
        if (element.mapsubject.includes('Lake')) {
          return element
        }
      }
      if (trailMaps) {
        if (element.mapsubject.includes('Trail Maps')) {
          return element
        }
      }
      if (parcel) {
        if (element.mapsubject.includes('Parcel')) {
          return element
        }
      }
    })

  }

}
