import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import './polyfills';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.isProduction) {
  enableProdMode();
  window.console.log = () => { }
  window.console.warn = () => { }
  window.console.error = () => { }
  window.console.time = () => { }
  window.console.timeEnd = () => { }
}



platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
