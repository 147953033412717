import { environment } from 'src/environments/environment';
import { UserManualInterface } from './user-manual.interface';
export const USERMANUAL_WEB: UserManualInterface[] = [
    {title: 'Introduction',key: 'intro', isDisplayed: true,contents: [
      {title: 'Overview',key: 'intro-overview',
      displays: [
        {type: 'heading-1',text: 'Overview', icon: 'public'},
        {type: 'paragraph',text: 'Current Version: <span class="bold">3.2.5</span> (March 2024)'},
        {type: 'paragraph',text: 'This guide describes how to use <span class="bold">BackCountry Navigator XE</span>. The web application allows you to manage your trips, purchase coins, upgrade membership and many more.'},
        {type: 'paragraph',text: 'We have made an offline version of our user guide. You can do this by downloading the PDF version.'},
        {type: 'button-nav',text: 'Download it here', link: `${environment.api.url}doc/pdf/BackCountry Navigator User Manual - web.pdf`},
      ],
      contents:[]},
      {title: 'Getting Started',key: 'getting-started',
      displays: [
        {type: 'heading-1',text: 'Getting Started'},
        {type: 'paragraph',text: 'Welcome to <span class="bold">BackCountry Navigator XE!</span>'},
        {type: 'paragraph',text: 'If you already have an account, login with the same credentials you use on the backcountry navigator XE android/iOS app. You can use the same type of login (username/password, Google, Facebook, Apple) on both.'},
        {type: 'paragraph',text: 'You can use a lot of the app and preview maps online without membership. A membership is required for saving offline maps and synching trips with your online account.'},
        {type: 'paragraph',text: 'Become a member today to get the full benefits of BackCountry Navigator XE!'},
        {type: 'button-basic',text: 'More About Membership', link: '/web/user-manual/membership'},
        {type: 'paragraph',text: 'If you are new to backCountry Navigator, start <a href="/#/web/user-manual/dashboard">here</a>',
        pdfText: 'If you are new to backCountry Navigator, start here.'},
      ],
      contents:[]},
      {title: 'FAQs',key: 'faqs',
       displays: [
        {type: 'heading-1',text: 'FAQs'},
        {type: 'paragraph',text: 'Here are the most common Frequently asked questions. You can view more FAQs <a style="text-decoration: underline; color:#7CB342" href="/#/web/user-manual/faqs">here</a>.'},
        {type: 'accordion', texts: []},
        {type: 'accordion',
        texts: [
         {title: 'Can I use the web app to sync trips with BCNAV Pro?', desc: 'No. The web app is designed for BackCountry Navigator XE (BCNAVXE). But you can migrate your trips from PRO to XE.'},
         {title: 'Can I display multiple trips at once?', desc: 'You cannot do that for now. But you can combine trips if that is your goal.'},
         {title: 'How can I maximize the use of the web app?', desc: 'The web app is designed to create your trips ahead, view your trips in a larger scale, print your trips, and allows you to directly save your trip files to your computer. You will have more control of editing and visualizing your trips. We tailored fit the web app for your needs.'},
         {title: 'Where can I get discounts or promo codes for membership?', desc: 'If you are already a member, BackCountry sends the latest updates to you and inform users promotions, events and what is currently happening in the App.'},
         {title: 'I forgot my password, and I cannot access my email. What should I do?', desc: 'You can submit a support ticket at <a style="text-decoration: underline; color:#7CB342" href="https://bcnavxe.freshdesk.com/support/home">BCNAVXE HelpDesk<a>.'},
   ]},
       ],
       contents:[]},
      {title: 'How-tos',key: 'how-tos-sub',
       displays: [
         {type: 'heading-1',text: 'How-tos'},
         {type: 'paragraph',text: 'Explore our <a style="text-decoration: underline; color:#7CB342" href="/#/web/user-manual/how-tos">collection</a>  of sample tutorials that will help you navigate through our web app.'},
         {type: 'how-to-card', texts: [
        //  { title: 'Apply Map Layer', key: '', imageUrl: './assets/images/docs/how-tos/basemap/apply-map-layer.png'},
        //  { title: 'Delete Bookmarks', key: '', imageUrl: './assets/images/docs/how-tos/bookmark/delete-bookmark.png'},
        //  { title: 'Buy Addons', key: '', imageUrl: './assets/images/docs/how-tos/basemap/buy-addons.png'},
        //  { title: 'Change Password', key: '', imageUrl: './assets/images/docs/how-tos/user-profile/change-password.png'},
        //  { title: 'Upgrade Membership', key: '', imageUrl: './assets/images/docs/how-tos/account-settings/upgrade-membership.png'},
        //  { title: 'Autorenew', key: '', imageUrl: './assets/images/docs/how-tos/account-settings/auto-renew.png'},
         ]}
       ],contents:[]},
      {title: 'Helpdesk',key: 'helpdesk',
       displays: [
        {type: 'heading-1',text: 'Helpdesk'},
        {type: 'paragraph',text: 'Need to contact support? Request a new feature? You can visit <a style="text-decoration: underline; color:#7CB342" href="https://bcnavxe.freshdesk.com/support/home">here</a>'},
       ],
      contents:[]},
      {title: 'BCNAVXE Mobile',key: 'bcnavxe-mobile',
      displays: [
        {type: 'heading-1',text: 'BCNAVXE Mobile'},
        {type: 'heading-2', text: 'Android'},
        {type: 'paragraph', text: 'Download our latest version of BCNAVXE for Android here:'},
        {type: 'paragraph', text: '<a style="text-decoration: underline; color:#7CB342" href="https://play.google.com/store/apps/details?id=com.crittermap.backcountrynavigator.xe" target="_blank">BackCountry Navigator XE for Android</a>'},
        {type: 'heading-2', text: 'iOS'},
        {type: 'paragraph', text: 'Download our latest version of BCNAVXE for iOS here:'},
        {type: 'paragraph', text: '<a style="text-decoration: underline; color:#7CB342" href="https://apps.apple.com/us/app/backcountry-navigator-xe/id1330376207" target="_blank">BackCountry Navigator XE for iOS</a>'},
      ],
      contents:[]},
      {title: 'Blogs',key: 'blogs',
       displays: [
        {type: 'heading-1',text: 'Recent Blogs'},
        {type: 'blog-card', texts: [
          {title: 'Thunderforest Subscription and Alternatives', tag1: 'Maps', tag2: 'Thunderforest', desc: 'As we have been warning in our menu for a year, Thunderforest has become a paid subscription map service. Fortunately, if you are...<span class="bold">Continue Reading</span>', date: 'Nov. 7, 2020', imageUrl: 'https://crittermap-bc865.firebaseapp.com/images/worldwide/thunderforest_landscape.png', link: 'https://www.backcountrynavigator.com/blog/2020/11/7/thunderforest-subscription-and-alternatives'},
          {title: 'Changes to Caltopo Maps Coming', tag1: 'Maps', tag2: 'Caltopo', desc: 'At the end of 2020, Caltopo will stop licensing its maps to other apps, like us.Fortunately, if you are using BackCountry Navigator, either...<span class="bold">Continue Reading</span>', date: 'Oct. 21, 2020', imageUrl: 'https://crittermap-bc865.firebaseapp.com/images/us_topo_maps_and_charts/caltopo_us_24k_topo_maps.jpg', link: 'https://www.backcountrynavigator.com/blog/2020/10/21/changes-to-caltopo-maps-coming'},

        ]},
       ],
        contents:[]},
  ]},
  {title: 'Dashboard', key: 'dashboard', isDisplayed: true,contents: [
    {title: 'Overview', key: 'dash-overview',
     displays: [
      {type: 'heading-1',text: 'Overview'},
      {type: 'paragraph',text: 'The dashboard contains the map where you can view, modify or customize your trips. Upon visiting <a style="text-decoration: underline; color:#7CB342" href="/">bcnavxe.com</a>, click the Dashboard on the navbar and you’ll be directed to the dashboard page.  This is where you’ll spend the most of your time.',
      pdfText: 'The dashboard contains the map where you can view, modify or customize your trips. Upon visiting bcnavxe.com, click the Dashboard on the navbar and you’ll be directed to the dashboard page.  This is where you’ll spend the most of your time.'
      },
      {type: 'paragraph',text: 'The default map view will navigate you to <span class="bold">37.02009839847755,-95.00976599999854</span> and uses the <span class="bold">BackCountry World Map (vector)</span> layer. You can change the default navigation by setting a home bookmark. You can find out more here (navigate to bookmark).',
      pdfText:'The default map view will navigate you to 37.02009839847755,-95.00976599999854 and uses the BackCountry World Map (vector) layer. You can change the default navigation by setting a home bookmark. You can find out more here (navigate to bookmark).'
      },
      {type: 'image', imageUrl: './assets/images/docs/images/dashboard/dashboard.gif', resizeScale: 0.4},
      {type: 'paragraph', text: 'To move the map, hold and drag your mouse to the direction you want it to move. To zoom in and out, you can simply use the scrollwheel of your mouse, if you don’t have a scrollwheel, you can click the + or - sign on the lower right of the dashboard.'},
      {type: 'image', imageUrl: './assets/images/docs/images/dashboard/dashboard_move.gif', resizeScale: 0.4},
      {type: 'paragraph', text: 'The dashboard has the tools setup for you to modify your trips.'},
      {type: 'image', imageUrl: './assets/images/docs/images/dashboard/dashboard_overview.png'},
     ],
    contents: []},
    {title: 'Map Tools', key: 'map-tools',
     displays: [
      {type: 'heading-1',text: 'Map Tools'},
      {type: 'paragraph',text: 'The map tools are located below the navbar, It allows you to search, upload file, go to home, update bookmarks, print map, draw and change map layers.'},
      {type: 'image', imageUrl: './assets/images/docs/images/dashboard/maptools.png'},
      {type: 'paragraph',text: 'The map tools are located below the navbar, It allows you to search, upload file, go to home, update bookmarks, print map, draw and change map layers.'},
      {type: 'image-mobile', imageUrl: './assets/images/docs/images/dashboard/maptools_mobile.png'},
      {type: 'paragraph',
      text: '<span class="bold">Note:</span> The mobile version of the map tools will be shown right beside the sidebar instead of below the navbar.',
      pdfText:  'Note: The mobile version of the map tools will be shown right beside the sidebar instead of below the navbar.'
      },
      {type: 'heading-2',text: 'Map Tools List', class:"mb-0"},
      {type: 'table', texts:[
        {title: 'Search', key: 'search', desc: 'The search feature will help you to find the place you want by centering/navigating it to the map.'},
        {title: 'Upload File', key: 'upload-file', desc: 'Accepts gpx and kml files to upload your trips.'},
        {title: 'Home', key: 'home', desc: 'When a home bookmark is set, clicking the home button will navigate you to it. This will be your default location on signin.'},
        {title: 'Bookmarks', key: 'bookmark', desc: 'You can add, delete or make a home bookmark. Bookmarks are useful for saving a specific location on the map, along with the current basemap to view it.'},
        {title: 'Print', key: 'print', desc: 'Allows you to download a pdf or image file of the map that you are currently viewing.'},
        {title: 'Draw', key: 'draw', desc: 'Allows you to create different geometries to add in your trip.'},
        {title: 'Basemap', key: 'basemap', desc: 'Adds an additional layer in the map. You can also buy add-ons here.'},
      ]},
     ],
     contents:[
    ]},
      {title: 'Search', key: 'search',
      displays:[
        {type: 'heading-1',text: 'Search'},
        {type: 'paragraph',text: 'The search feature will help you to find the place you want by centering/navigating it to the map. '},
        {type: 'image', imageUrl: './assets/images/docs/images/dashboard/search.png'},
        {type: 'paragraph',text: 'There are 2 search features you can find in the web application. The one on the landing page and the one on the dashboard.'},
        {type: 'how-to',text: 'How to use Search', link: 'h2-use-search'},
      ],
       contents:[], child: 'child-1'},
      {title: 'Upload File', key: 'upload-file',
      displays:[
        {type: 'heading-1',text: 'Upload File'},
        {type: 'paragraph',text: 'This feature allows you to upload your trips into the cloud.'},
        {type: 'image', imageUrl: './assets/images/docs/images/dashboard/uploadfile.png'},
        {type: 'paragraph',text: 'You can also upload files via Create New Trip, then choose Upload from Mobile/PC. Due to server limitations, you can upload files with sizes of up to 20 MB, but you can upload as many files as you want. You can upload GPX and KML files. After uploading it, you can immediately see your trips and customize it on the map. '},
        {type: 'image', imageUrl: './assets/images/docs/images/dashboard/uploadfile2.png'},
        {type: 'how-to',text: 'How to Upload gpx/kml to create a trip', link: 'h2-up-trip'},
      ],
       contents:[], child: 'child-1'},
      {title: 'Home', key: 'home', contents:[],
      displays:[
        {type: 'heading-1',text: 'Home'},
        {type: 'paragraph',text: 'The home button will navigate you to the selected home bookmark. To manage your bookmarks, click <a style="text-decoration: underline; color:#7CB342" href="/#/user-manual/dashboard/#bookmark">here</a>. ',
        pdfText: 'The home button will navigate you to the selected home bookmark. To manage your bookmarks, check the bookmarks guide. '},
        {type: 'image', imageUrl: './assets/images/docs/images/dashboard/home.png'},
        {type: 'paragraph',
        text: '<span class="bold">Note:</span> This only works  if you have set a home bookmark.',
        pdfText: 'Note: This only works  if you have set a home bookmark.'},
        {type: 'image', imageUrl: './assets/images/docs/images/dashboard/home_move.gif', resizeScale: 0.2},
      ],
      child: 'child-1'},
      {title: 'Bookmarks', key: 'bookmark',
      displays:[
        {type: 'heading-1',text: 'Bookmark'},
        {type: 'paragraph',text: 'Bookmarks allows you to save a specific location and go back to it anytime. You can also mark it as home. Home bookmarks will always be at the top of the bookmark list.'},
        {type: 'image', imageUrl: './assets/images/docs/images/dashboard/bookmark.png'},
        {type: 'how-to',text: 'How to add a Bookmark', link: 'h2-add-bookmark'},
        {type: 'how-to',text: 'How to edit a Bookmark', link: 'h2-remove-bookmark'},
        {type: 'how-to',text: 'How to delete a Bookmark', link: 'h2-delete-bookmark'},
        {type: 'how-to',text: 'How to navigate to a Bookmark', link: 'h2-navigate-bookmark'},
        {type: 'how-to',text: 'How to set a Bookmark as Home', link: 'h2-home-bookmark'},
      ],
      contents:[], child: 'child-1'},
      {title: 'Print', key: 'print',
      displays:[
        {type: 'heading-1',text: 'Print'},
        {type: 'paragraph',text: 'The print feature allows you to download a pdf or image file of the map that you are currently viewing.'},
        {type: 'image', imageUrl: './assets/images/docs/images/dashboard/print.png'},
        {type: 'how-to',text: 'How to print a map', link: 'h2-print-map'},
      ], contents:[], child: 'child-1'},
      {title: 'Draw', key: 'draw',
      displays:[
        {type: 'heading-1',text: 'Draw'},
        {type: 'paragraph',text: '"With the draw feature, you have the flexibility to incorporate various geometries into your trips. Customize your drawings by adjusting their color, icon, label, and description, or remove any trip segment you wish. For more detailed information, explore our <a style="text-decoration: underline; color:#7CB342" href="/#/user-manual/dashboard/#drawing-options">drawing options</a> in the user manual.',
        pdfText: 'The draw feature enables you to incorporate various geometries into your trips. Customize your drawings by adjusting their color, icon, label, and description, or remove any unwanted trip segments. For more information, refer to the Drawing Options Guide.'},
        {type: 'image', imageUrl: './assets/images/docs/images/dashboard/draw.png'},
        {type: 'paragraph',text: 'You will be unable to draw if you don’t have a trip opened. You have to first <a style="text-decoration: underline; color:#7CB342" href="/#/user-manual/dashboard/#drawing-options">Create a New Trip</a>.',
        pdfText: 'You will be unable to draw if you don’t have a trip opened. You have to first create a new trip.'},
      ],
      contents:[], child: 'child-1'},
        {title: 'Point', key: 'point',
        displays:[
          {type: 'heading-2',text: 'Point'},
          {type: 'paragraph',text: 'Points serve as valuable markers for landmarks, checkpoints, and references. There are two methods to create points: generating a single point or creating multipoints.'},
          {type: 'image', imageUrl: './assets/images/docs/images/dashboard/point.png'},
          {type: 'heading-2',text: 'Point Types', class: 'mb-0'},
          {type: 'table', texts:[
            {title: 'Point', desc: 'Creates a single point in the map.'},
            {title: 'Multipoint', desc: 'Creates multiple points in one instance.'},
          ]},
          {type: 'how-to',text: 'How to Create a Point', link: 'h2-create-point'},
          {type: 'how-to',text: 'How to Create Multiple Points', link: 'h2-create-points'},
        ],
        contents:[], child: 'child-2'},
        {title: 'Path', key: 'path',
        displays:[
          {type: 'heading-2',text: 'Path'},
          {type: 'paragraph',text: 'Paths show the paths you have walked in the area.'},
          {type: 'image', imageUrl: './assets/images/docs/images/dashboard/line.png'},
          {type: 'heading-2',text: 'Path Types', class: 'mb-0'},
          {type: 'table', texts:[
            {title: 'Path', desc: 'Used to create a line by clicking.'},
            {title: 'Freehand Path', desc: 'Used to create a line by dragging the mouse.'},
            {title: 'Auto Route (hiking)', desc: 'Finds a hiking path that will generate a path based on the line coordinates a user has entered.'},
            {title: 'Auto Route (cycling)', desc: 'Finds a cycling path that will generate a path based on the line coordinates a user has entered.'},
          ]},
          {type: 'how-to',text: 'How to Create a Path', link: 'h2-create-polyline'},
          {type: 'how-to',text: 'How to Create Path using Freehand', link: 'h2-freehand-polyline'},
          {type: 'how-to',text: 'How to Create Path using Autoroute', link: 'h2-line-autoroute'},
        ], contents:[], child: 'child-2'},
        {title: 'Shape', key: 'shape',
        displays:[
          {type: 'heading-2',text: 'Shape'},
          {type: 'paragraph',text: 'Shapes can be used to make boundaries.'},
          {type: 'image', imageUrl: './assets/images/docs/images/dashboard/shape.png'},
          {type: 'heading-2',text: 'Shape Types', class: 'mb-0'},
          {type: 'table', texts:[
            {title: 'Rectangle', desc: 'Creates a rectangular shaped polygon.'},
            {title: 'Circle', desc: 'Creates a circular shaped polygon.'},
            {title: 'Polygon', desc: 'Used to create a polygon by clicking.'},
            {title: 'Freehand Polygon', desc: 'Used to create a polygon by dragging the mouse.'},
          ]},
          {type: 'how-to',text: 'How to Create a Polygon using Rectangle/Circle', link: 'h2-preset-polygon'},
          {type: 'how-to',text: 'How to Create a Custom Polygon', link: 'h2-create-polygon'},
          {type: 'how-to',text: 'How to Create a Custom Polygon using Frehand', link: 'h2-freehand-polygon'},
        ],
        contents:[], child: 'child-2'},
      {title: 'Basemap', key: 'basemap',
      displays: [
        {type: 'heading-1',text: 'Basemaps'},
        {type: 'paragraph',text: 'The basemaps offers different map layers to apply in the map. Some map layers are available for free, based on membership or can be purchased by digital currency.'},
        {type: 'image', imageUrl: './assets/images/docs/images/dashboard/basemap.png'},
      ],
      child: 'child-1', contents:[]},
        {title: 'Map Sources', key: 'mem-map-layer',
        displays: [
          {type: 'heading-2',text: 'Map Sources'},
          {type: 'paragraph',text: 'Map Sources functionality enables you to integrate additional map layers into your current map view. The displayed maps are suggestions based on your current location, and moving the map will prompt new suggested maps. The default map is the <span class="bold">Backcountry World</span>, a vector topo map renowned for its excellent general utility. Should you frequently utilize a particular map layer, you can <span class="bold">mark it as a favorite</span>, ensuring it appears in your favorites list. Alternatively, you can <span class="bold">add a bookmark or designate it as your home map</span>, guaranteeing it loads automatically upon logging in.',
          pdfText: 'Map sources allows you to add a map layer to your current map view. The maps shown are suggested maps based on what part of the world you are currently in. Moving the map will change the suggested maps. The default map is the Backcountry world, a vector topo map with excellent general use characteristics. If you are going to use a map layer more often, you can mark it as favorites so it will be shown in the favorites list or you can add a bookmark/mark as home so you will always load that layer upon logging in.'
          },
          {type: 'image', imageUrl: './assets/images/docs/images/dashboard/basemap.png'},
          {type: 'paragraph',text: 'You can view all map layers on the web, but you need a membership plan to download them while in offline mode in the mobile app. The color on the rightmost side of the map layer indicates the membership needed to download it.'},
          {type: 'image', imageUrl: './assets/images/docs/images/dashboard/basemap_mem.png'},
          {type: 'how-to',text: 'How to Add Map Layer to Favorites', link: 'h2-layer-to-fav'},
          {type: 'how-to',text: 'How to Remove Map Layer to Favorites', link: 'h2-remove-to-fav'},
          {type: 'how-to',text: 'How to Use Map Layer', link: 'h2-use-layer'},
        ],
        contents:[], child: 'child-2'},
        {title: 'Add Ons', key: 'pur-map-layer',
        displays: [
          {type: 'heading-2',text: 'Add Ons'},
          {type: 'paragraph',text: 'Maps for purchase are available on the addons section. These map layers can’t be viewed on the web and is only available for purchase. Once you bought an addon, you can use it on bcnavxe mobile app.'},
          {type: 'image', imageUrl: './assets/images/docs/images/dashboard/addons.png'},
          {type: 'how-to',text: 'How to Purchase Add Ons', link: 'h2-purchase-addons'},
        ],
        contents:[], child: 'child-2'},
      {title: 'Sidebar', key: 'sidebar',
      displays: [
        {type: 'heading-1',text: 'Sidebar'},
        {type: 'paragraph',text: 'The sidebar is positioned on the left-hand side of the dashboard. It houses the list of uploaded and created trips, as well as options for your current trip and specific drawing functionalities.'},
        {type: 'image', imageUrl: './assets/images/docs/images/dashboard/sidebar.png'},
        {type: 'paragraph',text: '<span class="bold">Note: </span>If you’re not logged in, the sidebar will not appear. Only authenticated users will be able to see the sidebar.',
        pdfText: 'Note: If you’re not logged in, the sidebar will not appear. Only authenticated users will be able to see the sidebar.'
        },
        {type: 'table', texts:[
          {title: 'My Trips', key: 'my-trips', desc: 'Displays your list of trips, you can create a new trip here.'},
          {title: 'Trip Options', key: 'trip-info', desc: 'Displays tools for your current trip. This will only show if you have a trip opened.'},
          {title: 'Drawing Options', key: 'drawing-options', desc: 'Displays tools you can do for a specific drawing. This will only show if you have clicked a geometry (point, line, or shape).'},
        ]},
      ],
       contents:[]},
        {title: 'My Trips', key: 'my-trips',
        displays: [
          {type: 'heading-2',text: 'My Trips'},
          {type: 'paragraph',text: 'The My Trips section is the first part of the sidebar. It shows the list of your trip folders and the "Create New Trip" button. This part of the sidebar is always shown.'},
          {type: 'image', imageUrl: './assets/images/docs/images/dashboard/mytrips.png'},
          {type: 'paragraph',text: 'On the folders list, you can open your trips, delete or combine trips with your current trip.'},
          {type: 'image', imageUrl: './assets/images/docs/images/dashboard/mytripsfolder.png'},
          {type: 'how-to',text: 'How to Create a Blank Trip', link: 'how-to-combine-trip'},
          {type: 'how-to',text: 'How to Upload gpx/kml to create a trip', link: 'h2-up-trip'},
          {type: 'how-to',text: 'How to Combine Trips', link: 'h2-combine-trip'},
        ],
         contents:[], child: 'child-1'},
        {title: 'Trip Info', key: 'trip-info',
        displays: [
          {type: 'heading-2',text: 'Trip Info'},
          {type: 'paragraph',text: 'The My Trips section is the second part of the sidebar. It will be shown if an owned or shared trip is currently open. The Trip Info tab title is the name of the opened trip, it also shows if the opened trip is yours (owned) or someone else\s (shared)'},
          {type: 'image', imageUrl: './assets/images/docs/images/dashboard/tripinfo.png'},
          {type: 'table', texts:[
            {title: 'Trip Info', desc: 'Opens the Trip info tab. You can rename, change folder, change trip sharable access, add tags, export as gpx/kml here.'},
            {title: 'Save Trip', desc: 'Saves the current trip. Whatever you see on the map, that will be saved on your trip when you view it in your mobile or import it elsewhere.'},
            {title: 'Close Trip', desc: 'Closes the trip. Navigates you to your home bookmark if there is any.'},
            {title: 'Delete Trip', desc: 'Deletes the current trip. This cannot be undone. If you have mistakenly delete it, as long as you have the id, bcnavxe support might be able to retrieve it for you.'},
            {title: 'Reset Trip', desc: 'Resets the trip to the last saved state. If you have any changes on the trip but you don\'t want to save it, you can Reset your trip. This will only show if you made changes'},
          ]},
          {type: 'how-to',text: 'How to Share Trip', link: 'h2-share-trip'},
          {type: 'how-to',text: 'How to Rename/Change Trip Folder', link: 'h2-rename-trip'},
          {type: 'how-to',text: 'How to Export Trip', link: 'h2-export-trip'},
        ],
         contents:[], child: 'child-1'},
        {title: 'Drawing Options', key: 'drawing-options',
        displays: [
          {type: 'heading-2',text: 'Drawing Options'},
          {type: 'paragraph',text: 'The drawing options lets you edit your drawings. In order for the drawing options to show up, click any drawings on the map. Depending on the drawing (point, line, shape), the options will vary.'},
          {type: 'image', imageUrl: './assets/images/docs/images/dashboard/drawingoptions.png'},
          {type: 'paragraph',text: 'You can see the length/area of your trips. You also can: '},
          {type: 'table', texts:[
            {title: 'Change Drawing Name', desc: 'The drawing name is the text that is shown above the drawing.'},
            {title: 'Change Drawing Description', desc: 'Detailed description of your drawing. It can be seen when viewing your drawing or export it as gpx/kml.'},
            {title: 'Change color', desc: 'For paths and shape, you can change the color of the paths.'},
            {title: 'Change icon', desc: 'For points, we have a set of icons that can be used to make the points easier to distinguish.'},
          ]},
          {type: 'how-to',text: 'How to Change Path/Shape Color', link: 'h2-line-color'},
          {type: 'how-to',text: 'How to Change Point Icon', link: 'h2-point-icon'},
          {type: 'how-to',text: 'How to Change Drawing Name/Description', link: 'h2-change-label'},
        ],
        contents:[], child: 'child-1'},
      {title: 'Zoom', key: 'zoom',displays: [
        {type: 'heading-1',text: 'Zoom'},
        {type: 'paragraph',text: 'Located at the lower right of the screen, you can use these buttons to zoom in or out the map.'},
        {type: 'image', imageUrl: './assets/images/docs/images/dashboard/zoom.png'},
      ], contents:[]},
      {title: 'Map Layer Name',displays: [
        {type: 'heading-1',text: 'Map Layer Name'},
        {type: 'paragraph',text: 'The map layer name shows the current layer that is being used. To change the layer, see <a style="text-decoration: underline; color:#7CB342" href="/#/user-manual/dashboard/#basemap"> here. </a>',
        pdfText:'The map layer name shows the current layer that is being used. To change the layer, see the basemaps layer guide.'},
        {type: 'image', imageUrl: './assets/images/docs/images/dashboard/layername.png'},
      ], key: 'map-layer-name', contents:[]},
  ]},
  {title: 'Membership', key: 'membership',isDisplayed: true, contents: [
    {title: 'Overview', key: 'mem-overview',
    displays: [
      {type: 'heading-1',text: 'Overview'},
      {type: 'paragraph',text: 'The membership page presents the distinctions among membership plans. For a more comprehensive breakdown, visit the <a style="text-decoration: underline; color:#7CB342" href="/#/pricing-plans">pricing plans</a> page.',
      pdfText: 'The membership page shows you the differences between membership plans. If you want to see a more detailed breakdown. Go to pricing plans page.'},
      {type: 'image', imageUrl: './assets/images/docs/images/membership/membership2.png'},
      {type: 'paragraph',text: 'If you are already logged in, you will be redirected to your Account Settings.'},
      {type: 'image', imageUrl: './assets/images/docs/images/membership/membership_acc.gif',resizeScale: 0.18},
    ],
     contents: []}
  ]},
  {title: 'Help', key: 'help',isDisplayed: true, contents: [
    {title: 'Overview', key: 'help-overview'
    ,displays: [
      {type: 'heading-1',text: 'Overview'},
      {type: 'paragraph',text: 'The help button redirects you to <a style="text-decoration: underline; color:#7CB342" href="https://bcnavxe.freshdesk.com/support/home"> https://bcnavxe.freshdesk.com/support/home.</a>',
      pdfText: 'The help button redirects you to https://bcnavxe.freshdesk.com/support/home.'},
      {type: 'image', imageUrl: './assets/images/docs/images/help/help.png'},
      {type: 'paragraph',text: 'This is the bcnavxe helpdesk. You can contribute feature requests, look for tips and tricks for both mobile and web, check the latest news and submit a ticket if you have encounterd an issue.'},
      {type: 'image', imageUrl: './assets/images/docs/images/help/helpdesk.png'},
    ],
    contents: []},
  ]},
  {title: 'User Profile', key: 'user-profile',isDisplayed: true, contents: [
    {title: 'Overview', key: 'user-overview', displays: [
      {type: 'heading-1',text: 'Overview'},
      {type: 'paragraph',text: 'This page allows you to update your display name, change password, generate an Import Hash or change measurement units.'},
      {type: 'image', imageUrl: './assets/images/docs/images/userprofile/userprofile.png'},
      {type: 'table', texts:[
        {title: 'Display Name', desc: 'Name that will be displayed on your profile.'},
        {title: 'Username', desc: 'Used for logging in your credentials. This is where you’ll receive emails/notifications from BackCountry Navigator.'},
        {title: 'Password', desc: 'Your secret code/phrase along with your username to access your account. You can change password in the user profile.'},
        {title: 'Import Hash', desc: 'The code used for importing trips/files from BCN Pro to BCNXE.'},
        {title: 'Measurement Units', desc: 'Change this if you want a different measurement in your geometries. Available measurements: Miles, Kilometers, Feet, Meters Yards. These units also affect areas i.e miles^2'},
      ]},
      {type: 'how-to',text: 'How to Update Display Name', link: 'h2-display-name'},
      {type: 'how-to',text: 'How to Generate Import Hash', link: 'h2-generate-hash'},
      {type: 'how-to',text: 'How to Change Password', link: 'h2-change-password'},
      {type: 'how-to',text: 'How to Change Default Measurement', link: 'h2-measurement'},
    ], contents: []},
  ]},
  {title: 'Account Settings', key: 'account-settings', isDisplayed: true, contents: [
    {title: 'Overview', key: 'account-overview',
    displays: [
      {type: 'heading-1',text: 'Overview'},
      {type: 'paragraph',text: 'The Account Settings page manages your subscriptions and your coins.'},
      {type: 'image', imageUrl: './assets/images/docs/images/accountsettings/accountsettings.gif',resizeScale: 0.4},
      {type: 'table', texts:[
        {title: 'Coins Information', desc: 'Shows your total current coins. You can buy coins here.'},
        {title: 'Plan Information', desc: 'Shows a detailed list of your membership plan. You can update your payment information here.'},
        {title: 'Upgrade membership', desc: 'You can subscribe for a membership here or if you already have a plan, you can upgrade it to a higher tier.'},
        {title: 'Subscription and Invoices History', desc: 'You can view your payment histories and download invoices here.'},
      ]},
      {type: 'how-to',text: 'How to Buy Coins', link: 'h2-buy-coins'},
      {type: 'how-to',text: 'How to Auto Renew', link: 'h2-auto-renew'},
      {type: 'how-to',text: 'How to Update Payment Info', link: 'h2-billing-info'},
      {type: 'how-to',text: 'How to Download an Invoice', link: 'h2-download-invoice'},
    ],
    contents: []},
  ]},
  {title: 'How-tos', key: 'how-tos',isDisplayed: true, contents: [
    {title: 'How Tos', key: 'how-tos-sub',displays: [
      {type: 'heading-1',text: 'How Tos'},
      {type: 'paragraph',text: 'Here are our list of how tos.'},
      {type: 'search', text: 'Enter keyword'},
      {type: 'how-to-card', texts: [
        ]}
    ], contents:[]},
  ]},
  {title: 'FAQs', key: 'faqs', isDisplayed: true,contents: [
    {title: 'FAQs', key: 'faqs', displays: [
      {type: 'heading-1',text: 'Frequently Asked Questions'},
      {type: 'accordion',
         texts: [
          {title: 'Can I use the web app to sync trips with BCNAV Pro?',
          pdfText: 'No. The web app is designed for BackCountry Navigator XE (BCNAVXE). But you can migrate your trips from PRO to XE.',
          desc: '<span class="bold">A: </span>No. The web app is designed for BackCountry Navigator XE (BCNAVXE). But you can migrate your trips from PRO to XE.'},
          {title: 'Can I display multiple trips at once?',
          pdfText: 'You cannot do that for now. But you can combine trips if that is your goal.',
          desc: '<span class="bold">A: </span>You cannot do that for now. But you can combine trips if that is your goal.'},
          {title: 'How can I maximize the use of the web app?',
          pdfText: 'The web app is designed to create your trips ahead, view your trips in a larger scale, print your trips, and allows you to directly save your trip files to your computer. You will have more control of editing and visualizing your trips. We tailored fit the web app for your needs.',
          desc: '<span class="bold">A: </span>The web app is designed to create your trips ahead, view your trips in a larger scale, print your trips, and allows you to directly save your trip files to your computer. You will have more control of editing and visualizing your trips. We tailored fit the web app for your needs.'},
          {title: 'Where can I get discounts or promo codes for membership?',
          pdfText: 'If you are already a member, BackCountry sends the latest updates to you and inform users promotions, events and what is currently happening in the App.',
          desc: '<span class="bold">A: </span>If you are already a member, BackCountry sends the latest updates to you and inform users promotions, events and what is currently happening in the App.'},
          {title: 'I forgot my password, and I cannot access my email. What should I do?',
          pdfText: 'You can submit a support ticket at BCNAVXE HelpDesk.',
          desc: '<span class="bold">A: </span>You can submit a support ticket at <a style="text-decoration: underline; color:#7CB342" href="https://bcnavxe.freshdesk.com/support/home">BCNAVXE HelpDesk<a>.'},
          {title: 'What is the difference between Bronze, Silver ang Gold Plans?',
          pdfText: 'You can check at the pricing plans page.',
          desc: '<span class="bold">A: </span>You can check at the pricing plans <a style="text-decoration: underline; color:#7CB342" href="https://bcnavxe.com/#/pricing-plans">page<a>.'},
          {title: 'Is there a limit in uploading trips?',
          pdfText: 'Yes. We recommend files not exceeding 20MB as uploading files takes a lot of our storage. But you can upload as many trips as you want.',
          desc: '<span class="bold">A: </span>Yes. We recommend files not exceeding 20MB as uploading files takes a lot of our storage. But you can upload as many trips as you want.'},
          {title: 'Can I see other people\'s trips?',
          pdfText: 'Yes. As long as you have the link and it is set to public.',
          desc: '<span class="bold">A: </span>Yes. As long as you have the link and it is set to public.'},
          {title: 'Can I import all of my trips at once?',
          pdfText: 'As of now, you can only export 1 trip at a time to the web.',
          desc: '<span class="bold">A: </span>As of now, you can only export 1 trip at a time to the web.'},
          {title: 'I want a custom Map Layer. Is it possible to use other map layers?',
          pdfText: 'You can only select our preset map layers for now. You can submit a request at the BCNAVXE Helpdesk.',
          desc: '<span class="bold">A: </span>You can only select our preset map layers for now. You can submit a request at the BCNAVXE Helpdesk.'},
          {title: 'Say I want to go at X location, can the app generate a way for me?',
          pdfText: 'Yes. You can use the Autorute feature.',
          desc: '<span class="bold">A: </span>Yes. You can use the Autorute feature.'},
          {title: 'I want to use a different unit of measure, is it possible to change from miles to kilometers or other units?',
          pdfText: 'Yes. Go to User Profile and select the measurement you want.',
          desc: '<span class="bold">A: </span>Yes. Go to User Profile and select the measurement you want.'},
          {title: 'Is there a pdf version of this?',
          pdfText: 'Yes. You are currently reading it.',
          desc: 'Yes.'
          },
          {title: 'Is there a Manual for the Mobile Version?',
          pdfText: 'Yes. You can find it in the BCNAVXE Web App.',
          desc: '<span class="bold">A: </span>Yes. You can find it <a style="text-decoration: underline; color:#7CB342" href="https://www.manula.com/manuals/crittermap/backcountry-navigator-xe/1/en/topic/getting-started">here<a>.'},
    ]},
    ], contents: []}
  ]},
  {title: 'Directions', key: 'directions', isDisplayed: false, contents:[]}

]
