import { MapLoadingComponent } from './components/map-loading/map-loading.component';
import { FilterAddOnPipe } from './pipes/filter-addon.pipe';
import { AreYouSureComponent } from './components/are-you-sure/are-you-sure.component';
import { FilterMapFavoritePipe } from './pipes/filter-favorite-map.pipe';
import { RouterModule } from '@angular/router';
import { StatusContainerComponent } from './components/status-container/status-container.component';
import { BcnavxeSnackbarComponent } from './snackbar/snackbar.component';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from "@angular/common";
import { MatMenuModule } from '@angular/material/menu';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTreeModule } from '@angular/material/tree';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import {ClipboardModule} from '@angular/cdk/clipboard';
import {MatStepperModule} from '@angular/material/stepper';
import {MatCheckboxModule} from '@angular/material/checkbox';

import { NgModule } from "@angular/core";
import { MatRippleModule } from '@angular/material/core';
import { HttpClientModule } from '@angular/common/http';
import { SortPipe } from './pipes/sort.pipe';
import { PurchaseComponent } from './components/purchase/purchase.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { ShortenPipe } from './pipes/shorten.pipe';
import { FilterMapPipe } from './pipes/filter-map.pipe';
import { HomeFirstPipe } from './pipes/home-first.pipe';
import { DropdownDirective } from './directives/dropdown.directive';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { DownloadsComponent } from './components/downloads/downloads.component';

const MaterialComponents = [
  MatButtonModule,
  MatToolbarModule,
  MatIconModule,
  MatRippleModule,
  MatMenuModule,
  MatCardModule,
  MatDividerModule,
  MatSnackBarModule,
  MatSlideToggleModule,
  MatTabsModule,
  MatTableModule,
  MatPaginatorModule,
  MatDialogModule,
  MatTooltipModule,
  MatSidenavModule,
  MatListModule,
  MatExpansionModule,
  MatTreeModule,
  MatProgressSpinnerModule,
  MatProgressBarModule,
  MatAutocompleteModule,
  MatChipsModule,
  ClipboardModule,
  MatStepperModule,
  MatCheckboxModule
];

const SharedComponents = [
  StatusContainerComponent,
  PurchaseComponent,
  NotFoundComponent,
  MapLoadingComponent,
  AreYouSureComponent,
  DownloadsComponent
]


@NgModule({
  declarations: [
    BcnavxeSnackbarComponent,
    SharedComponents,
    SortPipe,
    ShortenPipe,
    FilterMapPipe,
    FilterAddOnPipe,
    FilterMapFavoritePipe,
    HomeFirstPipe,
    DropdownDirective,
    SafeHtmlPipe,
    DownloadsComponent
  ],
  entryComponents:[BcnavxeSnackbarComponent],
  imports: [
    CommonModule,
    HttpClientModule,
    RouterModule,
    MaterialComponents
  ],
  exports: [
    CommonModule,
    MaterialComponents,
    SharedComponents,
    SortPipe,
    ShortenPipe,
    FilterMapPipe,
    FilterAddOnPipe,
    FilterMapFavoritePipe,
    HomeFirstPipe,
    SafeHtmlPipe,
    DropdownDirective,
  ]
})

export class SharedModule{

}
