import { User } from './../../shared/models/user.model';
export const UserActionTypes = {
  GetUser: '[USER] GetUser',
  UpdateUser: '[USER] UpdateUser',
  UpdateFavoriteBaseMap: '[USER] UpdateFavoriteBaseMap',
  Clear: '[USER] Clear',
  UpdateAutoRenew: '[USER] UpdateAutoRenew'
}


export namespace UserActions {

  export class GetUser {
    static readonly type = UserActionTypes.GetUser;
  }

  export class UpdateUser {
    static readonly type = UserActionTypes.GetUser;
    constructor(public payload: User) { }
  }

  export class UpdateFavoriteBaseMap {
    static readonly type = UserActionTypes.UpdateFavoriteBaseMap;
    constructor(public payload: string[]) { }
  }

  export class UpdateAutoRenew {
    static readonly type = UserActionTypes.UpdateAutoRenew;
    constructor(public payload: boolean){}
  }

  export class Clear {
    static readonly type = UserActionTypes.Clear;
  }
}
