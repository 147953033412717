import { NavigationInterface } from './map';
export const DEFAULT_NAV: NavigationInterface = {
lat: 37.02009839847755,
long: -95.00976599999854,
zoom: 4,
basemapType: "wwbcnvector"
}

export class Defaults{
  public static readonly measurement = 'miles';
}

export const Colors = {
  INFO: '#00bfff',
  WARN: '#cc4f40',
  SUCCESS: '#7CB342',
} as const;
