import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn:'root'
})

export class MapHeaderService{
  cardType = new BehaviorSubject<any>(null);
  constructor(){}

  
}