export const DrawActionTypes = {
  SetGeometry: '[DRAW] SetGeometry',
  SetMeasurement: '[DRAW] SetMeasurement',
  GetIconList: '[DRAW] GetIconList',
  Clear: '[DRAW] Clear',
  SetIsDrawingUpdated: '[DRAW] SetIsDrawingUpdated'
} as const;


export namespace DrawActions{
  export class SetGeometry{
    static readonly type = DrawActionTypes.SetGeometry;
    constructor(public payload: {type: string | null, uid: number | null} ){}
  }

  export class GetIconList {
    static readonly type = DrawActionTypes.GetIconList;
  }

  export class SetIsDrawingUpdated {
    static readonly type = DrawActionTypes.SetIsDrawingUpdated;
    constructor(public payload: boolean){}
  }

  export class SetMeasurement{
    static readonly type = DrawActionTypes.SetMeasurement;
    constructor(public payload: {value: number, unit: string} | null){}
  }


  export class Clear {
    static readonly type = DrawActionTypes.Clear;
  }
}
