import { environment } from 'src/environments/environment';

import { APIRoutes } from './../constants/api_routes';
import { SnackbarColors } from './../constants/snackbar';
import { BcnavxeSnackbarComponent } from './../snackbar/snackbar.component';
import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { NavigationEnd, Router } from '@angular/router';
import { filter, switchMap, tap, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, interval } from 'rxjs';
import { Store } from '@ngxs/store';
import { UserDataState } from 'src/app/store/state/user-data.state';
import { UIActions } from 'src/app/store/actions/ui.actions';
import { UI } from '../models/ui.model';
import { UIState } from 'src/app/store/state/ui.state';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  constructor(private snackBar: MatSnackBar,private router: Router, private store: Store){}

  doneDrawingUI(): void{
    this.store.dispatch([
      new UIActions.SetUI(UI.ISDRAWINGMODE, false),
      new UIActions.SetUI(UI.ISSIDEBAROPEN, true),
      new UIActions.SetUI(UI.ISCARDOPEN, false),
      new UIActions.SetUI(UI.ISSIDEBARVISIBLE, true),
    ]);
    this.roleAccessInit();
  }

  auth3rdParty(app: string){
    let link: string = '';
    switch(app){
      case 'fb':
        link = APIRoutes.AuthFB
        break;
      case 'google':
        link = APIRoutes.AuthGoogle
        break;
      case 'apple':
        link = APIRoutes.AuthApple
        break;
      default:
        break;
    }
    // window.open(environment.api.url + link, '_self ');
    location.href = environment.api.url + link;
  }

  roleAccessInit(){
    let changeUI = [];
    switch(this.store.selectSnapshot(UserDataState.membership)){
      case 'Inactive':
      case 'Active':
      case 'Non-member':
        changeUI.push(new UIActions.SetUI(UI.CANACCESSHOME, true));
        changeUI.push(new UIActions.SetUI(UI.CANACCESSBOOKMARKS, true));
        changeUI.push(new UIActions.SetUI(UI.ISSIDEBARVISIBLE, true));
        changeUI.push(new UIActions.SetUI(UI.ISSIDEBAROPEN, true));
        changeUI.push(new UIActions.SetUI(UI.CANACCESSUPLOADTREK, true));
        changeUI.push(new UIActions.SetUI(UI.CANACCESSPRINT, true));
        changeUI.push(new UIActions.SetUI(UI.CANACCESSPRINT, true));
        changeUI.push(new UIActions.SetUI(UI.CANACCESSBASEMAPS, true));
        changeUI.push(new UIActions.SetUI(UI.CANACCESSDRAW, true));
        this.checkDrawingAccess();
        break;
      default:
        changeUI.push(new UIActions.SetUI(UI.ISSIDEBARVISIBLE, false));
        changeUI.push(new UIActions.SetUI(UI.ISSIDEBAROPEN, false));
        changeUI.push(new UIActions.SetUI(UI.CANACCESSUPLOADTREK, false));
        changeUI.push(new UIActions.SetUI(UI.CANACCESSPRINT, false));
        changeUI.push(new UIActions.SetUI(UI.CANACCESSPRINT, false));
        changeUI.push(new UIActions.SetUI(UI.CANACCESSBASEMAPS, true));
        changeUI.push(new UIActions.SetUI(UI.CANACCESSDRAW, false));
        break;
    }
    this.store.dispatch(changeUI);
  }

  checkDrawingAccess(){
    if(this.store.selectSnapshot(UIState.getActiveTrip)?.geoJSON?.originalName){
      this.store.dispatch(new UIActions.SetUI(UI.CANACCESSDRAW, true));
    }
  }


  getMidPoint(num: number): number{
    if(num >= 0){
      return Math.floor(num/2);
    }else{
      return num;
    }
  }

  numberTohex(num: number): string{
    if(num >= 0 && num <= 255){
      return num.toString(16).length <= 1 ?  '0' + num.toString(16) : num.toString(16);
    }else{
      return '';
    }
  }

  showSnackBar(message: string, action: string, duration: number,color: SnackbarColors): void{
    this.snackBar.openFromComponent(BcnavxeSnackbarComponent,
      { duration: duration, data: { message: message, action: action }, panelClass: ['bcn-snackbar','snackbar-'+ color]}
      );
  }
  // route checker
  routeCheck(){
    return this.router.events
    .pipe(
      filter(event=> event instanceof NavigationEnd)
    )
  }

  getCurrentRoute(){
    return this.router.events;
  }

  generateRandom(number: number): number[]{
    let arrayofValues = [];
    for(var x = 0; x<=number; x++){
      arrayofValues.push(Math.floor(Math.random()*(300-0+1)+0));
    }
    return arrayofValues;
  }

  loadingTimer(randomTimer: number[], maxCount: number){
    let progress = 0;
    let subj = new BehaviorSubject(progress);
    return subj.pipe(
      switchMap((period: number) => {
        return interval(randomTimer[period])
      }),
      tap((tick) => {
        progress = progress + 1;
        subj.next(progress);
        if (progress <= maxCount){
          subj.complete();
        }
      })
    )

  }
}
