import { UserDataModel } from './../../shared/models/user-data.model';
import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { UserDataActions } from '../actions/user-data.actions';





const DEFAULT_USERDATA_STATE: UserDataModel = {
  membership: null
};

@State<UserDataModel>({
  name: 'userdata',
  defaults: DEFAULT_USERDATA_STATE
})

@Injectable()

export class UserDataState{
  constructor(){}

  @Selector()
  static membership(state: UserDataModel): string | null{
    return state.membership;
  }

  @Action(UserDataActions.GetMembership)
  getTrips(ctx: StateContext<UserDataModel>, { payload }: UserDataActions.GetMembership){
    const state = ctx.getState();
    let memStatus = null;
    if (payload.membershipStatus && payload.membershipStatus === 'Active'){
      memStatus = 'Active'
    }else
    if (payload.membershipStatus && payload.membershipStatus === 'Inactive'){
      memStatus = 'Inactive'
    }else
    if (!payload.membershipStatus){
      memStatus = 'Non-member';
    }
    return ctx.setState({
      ...state,
      membership: memStatus
    })

  }

  @Action(UserDataActions.Clear)
  clear(ctx: StateContext<UserDataModel[]>) {
    const state = ctx.getState();
    return ctx.setState({
      ...state,
      ...DEFAULT_USERDATA_STATE
    })
  }



}
