import { addressInterface } from 'src/app/shared/constants/map';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterMapFavorite'
})
export class FilterMapFavoritePipe implements PipeTransform {

  transform(value: any[], favorites: string[], address?: addressInterface | null
    ): any[] {
    if(!value){
      return value;
    }
    if(!favorites){
      return [];
    }

    //location filter
    let filterLocationArray = value;
    if(address){
      filterLocationArray = value.filter((element)=>{
        if(element.location.ww === 'ww'){
          return element;
        }else if(element.location.country.includes(address?.country.toUpperCase()) && address?.country !== ''){
          return element;
        }else if(element.location.state.includes(address?.state.toUpperCase())  && address?.state !== ''){
          return element;
        }
    })
    }


    return filterLocationArray.filter((element: any)=>{
      if(favorites.includes(element.shortname)){
        return element;
      }
    })
  }

}
