export class APIRoutes{


  // Map
  public static readonly getSavedMap = 'getSavedMap';
  public static readonly getHomeBookmark = 'getHomeBookmark';
  public static readonly getBookMarkedMap = 'getBookMarkedMap';
  public static readonly saveBookMarkInfo = 'saveBookMarkInfo';
  public static readonly deleteBookmark = 'deleteBookmark';
  public static readonly getMapSources = 'getMapSources';
  public static readonly getAddOns = 'mobile/allAddons';
  public static readonly getMapSource = 'getMapSource';
  public static readonly addToFavorite = 'addToFavorite';
  public static readonly getIconList = 'mobile/iconlist';


  // Auth
  public static readonly registerUser = 'registerUser';
  public static readonly login = 'login';
  public static readonly refreshToken = 'refreshToken';
  public static readonly logout = 'logout';
  public static readonly AuthFB = 'auth/facebook';
  public static readonly AuthGoogle = 'auth/google';
  public static readonly AuthApple = 'auth/apple';

  // User
  public static readonly getUserInfo = 'getUserInfo';
  public static readonly updateUser = 'updateUser';
  public static readonly mobileImportHash = 'mobile/importHash';
  public static readonly getPaymentHistory = 'getPaymentHistory';
  public static readonly mobileInvoice = 'mobile/invoice';
  public static readonly changePassword = 'changePassword';
  public static readonly ForgotPassword = 'forgotPassword';
  public static readonly BuyAddOns = 'mobile/BuyAddons';
  public static readonly membershipCustomerTransaction = 'membershipCustomerTransaction';
  public static readonly buyCoin = 'coinPackages/purchase';
  public static readonly autorenew = 'autorenew';
  public static readonly addCustomerCard = 'addCustomerCard';


  // Payments
  public static readonly coinPackages = 'coinPackages';
  public static readonly createCheckoutSession = 'createCheckoutSession';
  public static readonly createPaymentIntent = 'createPaymentIntent';

  // Files
  public static readonly convertToGeoJson = 'convertToGeoJson';
  public static readonly uploadTrip = 'mobile/file';
  public static readonly getTrip = 'mobile/trips/';
  public static readonly deleteTrip = 'deleteSelectedTrek/';
  public static readonly updateTrip = 'updateMapDrawing';
  public static readonly downloadFile = 'mobile/kmlfile';

  // 3rd Party API Routes
  public static readonly googleMapGeocode = 'https://maps.googleapis.com/maps/api/geocode/json';
  public static readonly openRouteService = 'https://api.openrouteservice.org/v2/directions/';
}
