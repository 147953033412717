export const UserDataActionTypes = {
  GetMembership: '[USERDATA] GetMembership',
  Clear: '[USERDATA] Clear',
  // GetMembershipType = ''
}



export type MembershipTypes = 'Non-member' | 'Active' | 'Inactive';



export namespace UserDataActions {
  export class GetMembership {
    static readonly type = UserDataActionTypes.GetMembership
    constructor(public payload: any){}
  }

  export class Clear {
    static readonly type = UserDataActionTypes.Clear;
  }
}
