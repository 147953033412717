import Graphic from '@arcgis/core/Graphic';
import { CoordinatesObj, addressInterface } from './../../shared/constants/map';
import { SearchResultsInterface } from './../../shared/constants/search_results';
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";
import Map from "@arcgis/core/Map";
import MapView from "@arcgis/core/views/MapView";
import Collection from '@arcgis/core/core/Collection';
import { IGraphicData } from 'src/app/dashboard/map/side-nav/drawing-contents/drawing-content.interface';

export const MapActionTypes = {
  GetMap: '[MAP] GetMap',
  GetMapCompleted: '[MAP] GetMapCompleted',
  GetMapView: '[MAP] GetMapView',
  UpdateMainGraphics: '[MAP] UpdateMainGraphics',
  ClearMainGraphics: '[MAP] ClearMainGraphics',
  DeleteMainGraphics: '[MAP] DeleteMainGraphics',
  GetDrawGraphics: '[MAP] GetDrawGraphics',
  Clear: '[MAP] Clear',
  SetSearchResult: '[MAP] SetSearchResult',
  SetAddress: '[MAP] SetAddress',
  SetCenter: '[MAP] SetCenter'
} as const;


export namespace MapActions{

  export class GetMap {
    static readonly type = MapActionTypes.GetMap;
    constructor(public payload: Map){}
  }

  export class GetMapCompleted {
    static readonly type = MapActionTypes.GetMapCompleted;
  }

  export class GetMapView {
    static readonly type = MapActionTypes.GetMapView;
    constructor(public payload: MapView) { }
  }


  export class UpdateMainGraphics{
    static readonly type = MapActionTypes.UpdateMainGraphics;
    constructor(public payload: IGraphicData){}
  }

  export class ClearMainGraphics{
    static readonly type = MapActionTypes.ClearMainGraphics;
  }

  export class DeleteMainGraphic {
    static readonly type = MapActionTypes.DeleteMainGraphics;
    constructor(public payload: number) { }
  }

  export class GetDrawGraphics {
    static readonly type = MapActionTypes.GetDrawGraphics;
    constructor(public payload: GraphicsLayer) { }
  }

  export class Clear {
    static readonly type = MapActionTypes.Clear;
  }

  export class SetSearchResult{
    static readonly type = MapActionTypes.SetSearchResult
    constructor(public payload: SearchResultsInterface | null){}
  }

  export class SetCenter{
    static readonly type = MapActionTypes.SetCenter
    constructor(public payload: CoordinatesObj | null){}
  }

  export class SetAddress{
    static readonly type = MapActionTypes.SetAddress
    constructor(public payload: addressInterface | null){}
  }
}
