<mat-toolbar [ngClass]="{'light-theme-header': !isAuthenticated,'dark-theme-header': isAuthenticated}">
  <div class="tool-bar">

    <a class="nav-logo-btn" routerLink="/">
      <img class="bcnavxe_logo" src="../../assets/images/logo/bcnavxe_logo.png" alt="bcnavlogo">
    </a>

    <div class="nav-item-container">
      <a class="nav-item" [routerLink]="(activeTrip$ | async) ? ['/dashboard/trek',(activeTrip$ | async)!.geoJSON?.originalName]:['/dashboard']" routerLinkActive="active"
        [routerLinkActiveOptions]="{exact: true}">DASHBOARD</a>
      <a class="nav-item" [routerLink]="isAuthenticated ? ['/dashboard/account-settings'] : ['/membership']" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">MEMBERSHIP</a>
      <a class="nav-item" (click)="navigateToHelp()">HELP</a>
      <a class="nav-item" [matMenuTriggerFor]="userManual"  #userManualTrigger="matMenuTrigger" >GUIDE</a>
      <mat-menu #userManual="matMenu"  xPosition="before" MenuPositionY="below" [class]="(accessToken$ | async) ? 'manual-menu-login' :'manual-menu-logout'">
        <button mat-menu-item [routerLink]="['android/user-manual/intro']"><span><mat-icon>android</mat-icon>Android</span></button>
        <button mat-menu-item [routerLink]="['ios/user-manual/intro']"><span><mat-icon>apple</mat-icon>iOS</span> </button>
        <button mat-menu-item [routerLink]="['web/user-manual/intro']"><span><mat-icon>public</mat-icon>Web</span> </button>
      </mat-menu>
      <a class="nav-item" *ngIf="user && user.userRole === 'Admin'" [routerLink]="['/admin/pdf']">ADMIN</a>
      <a class="nav-item" routerLink="/auth/login" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" *ngIf="!isAuthenticated">LOGIN</a>
      <a class="nav-item" *ngIf="isAuthenticated" [matMenuTriggerFor]="userProfile"  #userProfileTrigger="matMenuTrigger"><mat-icon>account_circle</mat-icon></a>
      <mat-menu #userProfile="matMenu"  xPosition="before" MenuPositionY="below" class="settings-menu">
        <ng-container *ngIf="user; else loadingContainer">
            <h6>{{user.firstname}}</h6>
            <ng-container *ngIf="user.membership.membershipType; else nonMember">
              <p><span [ngClass]="{'gold': user.membership.membershipType === 'Gold', 'silver': user.membership.membershipType === 'Silver', 'bronze' : user.membership.membershipType === 'Bronze' }">{{user.membership.membershipType}}</span> Member</p>
            </ng-container>
            <ng-template #nonMember>
              <p>Non-member</p>
            </ng-template>
        </ng-container>
        <ng-template #loadingContainer>
         <h6>Getting user info...</h6>
         <p>Getting membership info...</p>
        </ng-template>
        <mat-divider></mat-divider>
        <button mat-menu-item routerLink="/dashboard/user-profile"><span><mat-icon>person</mat-icon>User Profile</span></button>
        <button mat-menu-item routerLink="/dashboard/account-settings"><span><mat-icon>settings</mat-icon>Account Settings</span> </button>
        <div class="btn-container">
          <button mat-stroked-button color="primary" class="btn-block" (click)="onLogout()"  [class.spinner]="(isLogoutLoading$ | async)" [disabled]="(isLogoutLoading$ | async)">Logout</button>
        </div>

      </mat-menu>
      <div class="nav-flex-container" *ngIf="!isAuthenticated">
        <button routerLink="/auth/signup"  mat-raised-button color="primary" >SIGN UP</button>
      </div>
    </div>

    <div class="nav-item-responsive-container">
      <button mat-icon-button (click)="dropDownTrigger()">
        <mat-icon class="menu-icon" [ngClass]="{'black-icon': !isAuthenticated,'white-icon': isAuthenticated}">menu</mat-icon>
      </button>
    </div>
  </div>
</mat-toolbar>
<div class="invisible-div"></div>
<div class="dropdown-menu" [@dropDownState]="state" [style.display]="state === 'closed' ? 'none' : 'flex'" #dropDownMenu>
      <ng-container *ngIf="(activeTrip$ | async) as activeTrip; else noActiveTrip"
      >
      <button mat-button class="dropdown-btn" (click)="navigate('/dashboard/trek', activeTrip.geoJSON?.originalName)" [routerLink]="(activeTrip$ | async) ? ['/dashboard/trek',(activeTrip$ | async)!.geoJSON?.originalName]:['/dashboard']" routerLinkActive="active"
      [routerLinkActiveOptions]="{exact: false}" routerLinkActive="btn-highlight" [routerLinkActiveOptions]="{exact: true}">DASHBOARD</button>
    </ng-container>
    <ng-template #noActiveTrip>
      <button mat-button class="dropdown-btn" (click)="navigate('/dashboard')" [routerLink]="(activeTrip$ | async) ? ['/dashboard/trek',(activeTrip$ | async)!.geoJSON?.originalName]:['/dashboard']" routerLinkActive="active"
      [routerLinkActiveOptions]="{exact: false}" routerLinkActive="btn-highlight" [routerLinkActiveOptions]="{exact: true}">DASHBOARD</button>
    </ng-template>
      <button mat-button class="dropdown-btn" (click)="navigate(isAuthenticated?'/dashboard/account-settings':'/membership')" routerLinkActive="btn-highlight" [routerLinkActiveOptions]="{exact: true}">MEMBERSHIP</button>
      <button mat-button class="dropdown-btn" (click)="navigateToHelp()">HELP</button>
      <button mat-button class="dropdown-btn" (click)="navigate('/web/user-manual/intro',null,'intro-overview')" routerLinkActive="btn-highlight">GUIDE (WEB)</button>
      <button mat-button class="dropdown-btn" (click)="navigate('/android/user-manual/intro',null,'intro-overview')" routerLinkActive="btn-highlight">GUIDE (ANDROID)</button>
      <button mat-button class="dropdown-btn" (click)="navigate('/ios/user-manual/intro',null,'intro-overview')" routerLinkActive="btn-highlight">GUIDE (IOS)</button>
      <ng-container *ngIf="!isAuthenticated; else notAuthenticated">
        <button mat-button class="dropdown-btn" (click)="navigate('/auth/login')" routerLinkActive="btn-highlight" [routerLinkActiveOptions]="{exact: true}">LOGIN</button>
        <button mat-button class="dropdown-btn" (click)="navigate('/auth/signup')" routerLinkActive="btn-highlight" [routerLinkActiveOptions]="{exact: true}">SIGN UP</button>
      </ng-container>
      <ng-template #notAuthenticated>
        <button mat-button class="dropdown-btn" (click)="navigate('/dashboard/user-profile')" routerLinkActive="btn-highlight" [routerLinkActiveOptions]="{exact: true}">USER PROFILE</button>
        <button mat-button class="dropdown-btn" (click)="navigate('/dashboard/account-settings')" routerLinkActive="btn-highlight" [routerLinkActiveOptions]="{exact: true}">ACCOUNT SETTINGS</button>
        <button mat-button class="dropdown-btn" (click)="onLogout()">LOG OUT</button>
      </ng-template>

</div>
