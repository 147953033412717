<div class="download-card">
  <div class="download-header-container">
    <div class="download-header">
        <h4>Upload Complete</h4>
        <div class="icon-container">
        <a mat-icon-button> <mat-icon class="mat-icon-size" (click)="collapse()">{{isExpanded ? 'expand_more' : 'expand_less'}}</mat-icon></a>
        <a mat-icon-button> <mat-icon>close</mat-icon></a>
        </div>
    </div>
  </div>
  <div class="download-body-container">
    <div class="download-body">
      <div class="download-item">
        <div class="download-text">
          <a mat-icon-button class="printer-link">
            <mat-icon>article</mat-icon>
          </a>
          <p>usermanual.pdf</p>
        </div>
        <div class="progress-bar-container">
          <mat-progress-bar color="primary" mode="determinate" [bufferValue]="100">
          </mat-progress-bar>
        </div>
      </div>
    </div>
  </div>
</div>
