import { UserActions } from 'src/app/store/actions/user.actions';
import { Store } from '@ngxs/store';
import { take } from 'rxjs/operators';
import { interval, Observable, Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { successDataInterface } from '../../models/stripe-models';

@Component({
  selector: 'app-purchase',
  templateUrl: './purchase.component.html',
  styleUrls: ['./purchase.component.scss', '../not-found/not-found.component.scss']
})
export class PurchaseComponent implements OnInit {
  successData!: successDataInterface;

  timer: Observable<any> = interval(1000);
  timerSub!: Subscription;
  counter: number = 5;
  constructor(private router: Router, private store: Store) {
    this.successData = this.router.getCurrentNavigation()?.extras.state as successDataInterface;
  }

  ngOnInit(): void {
    this.checkIfHasData();
    this.updateUserFromStore()
    this.timerSub = this.timer.pipe(
      take(5),
    ).subscribe(() => {
        this.counter--;
       if(this.counter === 0){
         this.router.navigate(['/dashboard']);
       }
      })
  }

  ngOnDestroy(){
    this.timerSub.unsubscribe();
  }

  checkIfHasData(){
    if(!this.successData){
      this.router.navigate(['/404-not-found'])
    }
  }

  updateUserFromStore(){
    this.store.dispatch(new UserActions.GetUser());
  }
}
