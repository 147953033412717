import { MeasurementTypes, Membership } from './../../shared/models/user.model';
import { HttpUserService } from '../../shared/services/http-user.service';
import { User } from '../../shared/models/user.model';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { UserActions } from '../actions/user.actions';
import { state } from '@angular/animations';

const DEFAULT_USER_STATE: User = {
  address: '',
  coins: '',
  customSignUp: '',
  customerId: '',
  favoriteBasemap: [],
  firstname: '',
  importhash: '',
  lastname: '',
  membership: <Membership>{},
  newAccount: '',
  userRole: '',
  username: '',
  _id: '',
  measurement: 'kilometers',
  hasLoaded: false
}

@State<User>({
  name: 'user',
  defaults: DEFAULT_USER_STATE
})
  @Injectable()
export class UserState{
  constructor(private httpUserService: HttpUserService){}
  @Selector()
  static user(state: User): User{
    return state;
  }

  @Selector()
  static measurement(state: User): MeasurementTypes | undefined{
    return state.measurement;
  }

  @Selector()
  static hasLoaded(state: User): boolean | undefined{
    return state.hasLoaded;
  }

  @Selector()
  static membership(state: User): any{
    return state.membership;
  }

  @Action(UserActions.GetUser)
  getUser(ctx: StateContext<User>){
    return this.httpUserService.getUserInfo().pipe(
      tap((user)=>{
        const state = ctx.getState();
        ctx.setState({
          ...state,
          ...user,
          hasLoaded: true
        })
      })
    )
  }

  @Action(UserActions.UpdateUser)
  updateUser(ctx: StateContext<User>, { payload }: UserActions.UpdateUser){
    const state = ctx.getState();
   return ctx.setState({
      ...state,
      ...payload
    })
  }

  @Action(UserActions.UpdateAutoRenew)
  updateAutoRenew(ctx: StateContext<User>, { payload }: UserActions.UpdateAutoRenew){
    const state = ctx.getState();
    const data ={
      autorenew: payload
    }
   return this.httpUserService.autoRenew(data).pipe(
      tap((response)=>{
        ctx.setState({
          ...state,
          membership: {
            ...state.membership,
            autorenew: response
          }
        })
      })
    )
  }

  @Action(UserActions.UpdateFavoriteBaseMap)
  UpdateFavoriteBaseMap(ctx: StateContext<User>, { payload }: UserActions.UpdateFavoriteBaseMap){
    const state = ctx.getState();
    return ctx.setState({
      ...state,
      favoriteBasemap: payload
    })
  }

  @Action(UserActions.Clear)
  clear(ctx: StateContext<User>) {
    const state = ctx.getState();
    return ctx.setState({
      ...state,
      ...DEFAULT_USER_STATE
    })
  }
}
