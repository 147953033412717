import { membershipInterface } from './../models/stripe-models';
import { SnackbarColorType } from './../constants/snackbar';
import { Store } from '@ngxs/store';
import { APIRoutes } from './../constants/api_routes';
import { InvoiceInterface } from './../models/invoice.model';
import { PaymentHistoryInterface } from './../models/payment-history.model';
import { SharedService } from './shared.service';
import { User } from './../models/user.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import {Observable, throwError } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { UserActions } from 'src/app/store/actions/user.actions';
import { UtilitiesService } from './utilities.service';



@Injectable({
  providedIn: 'root'
})
export class HttpUserService {
  // user = new BehaviorSubject<any>(null);


  constructor(private http: HttpClient, private sharedService: SharedService,
    private store: Store, private utilitiesService: UtilitiesService) { }

  updateCustomerInfo(data: {stripeToken: string, customerId: string}){
    return this.http.post(APIRoutes.addCustomerCard, data).pipe(
      tap((response)=>{
      })
    )
  }

  autoRenew(data: {autorenew: boolean}){
    return this.http.post(APIRoutes.autorenew, data).pipe(
      map((response: any)=>{
          let message = '';
          if(response.data.autorenew === true){
            message = 'Your subscription will be automatically renewed.';
          }else{
            message = 'You have turned off auto renew.';
          }
          this.sharedService.showSnackBar(message, 'Dismiss', 5000, SnackbarColorType.SUCCESS);
          return response.data.autorenew
      }),
      catchError((error)=>{
        this.sharedService.showSnackBar('Unable to update auto-renew', 'Dismiss', 5000, SnackbarColorType.WARNING);
        return throwError(error);
      })
    )
  }

  buyCoin(data: {packageId: string, token: string}){
    return this.http.post(APIRoutes.buyCoin, data).pipe(
      tap((response: any)=>{
      })
    )
  }

  membershipCustomerTransaction(data: membershipInterface){
    return this.http.post(APIRoutes.membershipCustomerTransaction, data).pipe(
      tap((response: any)=>{
      }),
      catchError((error)=>{
        if(error.error.responseText === "error"){
          this.sharedService.showSnackBar('Invalid coupon', 'Dismiss', 5000, SnackbarColorType.WARNING);
        }
        return throwError(error);
      })
    )
  }

  buyAddOn(data: {shortname: string, coinPaid: number}){
    return this.http.post(APIRoutes.BuyAddOns, data).pipe(
      tap((response)=>{
      })
    )
  }

  getUserInfo(): Observable<User> {
    return this.http.get<User>(APIRoutes.getUserInfo).pipe(
      map((user: User)=>{
        // this.user.next(user);
        const mappedUser: User = {...this.utilitiesService.validateUser(user)}
        return mappedUser;
      }),
      catchError((error) => {
      return throwError(null);
      }
      )
    )
  }


  updateUser(data: {firstname: string, measurement: string, email: string}): Observable<User>{
    return this.http.post(APIRoutes.updateUser,data).pipe(
      map((response: any)=>{
        // this.user.next(response.userInfo);
        this.store.dispatch(new UserActions.UpdateUser(response.userInfo));
        this.sharedService.showSnackBar('User has been successfully updated!', 'Dismiss', 5000, SnackbarColorType.SUCCESS);
        return response.userInfo
      }),
      catchError((error) => {
        const errorMessage = 'An unknown error occured.'
        this.sharedService.showSnackBar(errorMessage, 'Dismiss', 5000, SnackbarColorType.WARNING);
        return throwError(null);
      })
    )
  }

  getNewHash(): Observable<string>{
    return this.http.get(APIRoutes.mobileImportHash).pipe(
      map((response: any)=>{
        this.sharedService.showSnackBar('Updated hash successfully!', 'Dismiss', 5000, SnackbarColorType.SUCCESS);
        return response.payload.importhash;
      })
    )
  }

  getPaymentHistory(): Observable<any[]>{
    return this.http.get(APIRoutes.getPaymentHistory).pipe(
      map((response: any)=>{
        if(response.history){
          const paymentHistory: PaymentHistoryInterface[] = [];
          response.history.forEach((history: any)=>{
            paymentHistory.push({
              date_sub: new Date(history.transDate),
              plan: history.membershipType,
              payment_card: history.cardNo,
              card_type: history.cardType,
              amount: history.amount,
              date_exp: history.validTo,
              trans_id: history.subscriptionId,
              status: history.status
            })
          })

          return paymentHistory.reverse();
        }
        return [];
      })
    )
  }

  getInvoice(): Observable<any[]>{
    return this.http.get(APIRoutes.mobileInvoice).pipe(
      map((response: any)=>{
        if(response.payload){
          const invoiceArray: InvoiceInterface[] = [];
          response.payload.forEach((invoice: any) => {
            invoiceArray.push({
              date_sub: new Date(invoice.date*1000),
              desc: invoice.lines.data[0].description,
              amount: invoice.lines.data[0].amount/100,
              download: invoice.invoice_pdf,
              status: invoice.paid ? 'Paid' : 'Pending'
            })
          })
          return invoiceArray;
        }
        return [];
      })
    )
  }

  changePassword(data: { username: string, changePassword: { OldPassword: string, NewPassword: string } }):Observable<any>{
    return this.http.post(APIRoutes.changePassword,data).pipe(
      map((response: any) => {
        if(response.message === 'false'){
          this.sharedService.showSnackBar('Password failed to change.', 'Dismiss', 5000, SnackbarColorType.WARNING);
        }else if(response.message === 'Password field does not exist'){
          this.sharedService.showSnackBar('Password failed to change. You might have logged in using a 3rd party app.', 'Dismiss', 5000, SnackbarColorType.WARNING);
        }else if(response.message === 'password reset successful'){
          this.sharedService.showSnackBar('Password Successfully Changed!', 'Dismiss', 5000, SnackbarColorType.SUCCESS);
        }

        return response;
      }),
      catchError((error) => {
        const errorMessage = 'An unknown error occured.'
        this.sharedService.showSnackBar(errorMessage, 'Dismiss', 5000, SnackbarColorType.WARNING);
        return throwError(null);
      })
    )
  }

}
