import { Router } from '@angular/router';
import { SharedService } from './../shared/services/shared.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { LandingService } from '../landing/landing-page/landing.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnDestroy {
  landingSub!: Subscription;
  routerSub!: Subscription;
  isLoaded:boolean = false;
  notDashboard: boolean = true;
  notDashboardLogic!: boolean;
  constructor(private landingService: LandingService, private sharedService: SharedService, private router: Router) {
    this.landingSub = this.landingService.isLoading.subscribe((value: boolean)=>{
      this.isLoaded = value;
    });
    this.notDashboardLogic = this.router.url === "/dashboard" || this.router.url.includes('/dashboard/trek')
    this.notDashboard = (this.notDashboardLogic ? false : true);
    this.routerSub = this.sharedService.routeCheck().subscribe((route: any) => {
      this.notDashboard = (route.url === "/dashboard" || route.url.includes('/dashboard/trek') ? false : true);
    });
   }

  ngOnInit(): void {
  }



  ngOnDestroy(){
    this.landingSub.unsubscribe();
  }
}
