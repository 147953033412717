import { SharedService } from './../../services/shared.service';
import { UserState } from './../../../store/state/user.state';
import { UserActions } from 'src/app/store/actions/user.actions';
import { AuthActions } from 'src/app/store/actions/auth.actions';
import { mergeMap } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnDestroy, OnInit } from "@angular/core";
import { Subscription } from 'rxjs';
import { UserDataActions } from 'src/app/store/actions/user-data.actions';

@Component({
  selector: 'app-oauth',
  templateUrl: './oauth.component.html',
  styleUrls: ['./oauth.component.scss']
})
export class OAuthAcomponent implements OnInit, OnDestroy{
  oAuthSubscription$!: Subscription;
  constructor(private route: ActivatedRoute, private router: Router, private store: Store, private sharedService: SharedService){}

  ngOnInit(){
    this.oAuthSubscription$ = this.route.queryParams
    .pipe(
      mergeMap((params)=>{
        localStorage.setItem('_access_token', JSON.stringify(params.accessToken));
        localStorage.setItem('_refresh_token', JSON.stringify(params.refreshToken));
        return this.store.dispatch(
        [
        new AuthActions.UpdateAccessToken(params.accessToken),
        new AuthActions.UpdateRefreshToken(params.refreshToken)
      ])
    }),
    )
    .subscribe(()=>{
      this.router.navigate(['/dashboard'])
      this.store.dispatch(new UserActions.GetUser()).subscribe(()=>{
        this.store.dispatch(new UserDataActions.GetMembership(this.store.selectSnapshot(UserState.membership))).subscribe(()=>{
          this.sharedService.roleAccessInit();
        })
      })
    })
  }

  ngOnDestroy(){
    this.oAuthSubscription$.unsubscribe();
  }
}
