export type GeometryTypes = "point" | "multipoint" | "polyline" | "polygon" | "rectangle" | "circle";

export const Geometries = {
  POINT: 'point',
  MULTIPOINT: 'multipoint',
  POLYLINE: 'polyline',
  POLYGON: 'polygon',
  RECTANGLE: 'rectangle',
  CIRCLE: 'circle',
  ELLIPSE: 'ellipse'
} as const;
