import { DrawActions } from 'src/app/store/actions/draw.actions';
import { UI } from './../../shared/models/ui.model';
import { UIActions } from 'src/app/store/actions/ui.actions';
import { SharedService } from './../../shared/services/shared.service';
import SimpleFillSymbol from '@arcgis/core/symbols/SimpleFillSymbol';
import { Geometries } from './../../shared/constants/geometry_types';
import SketchViewModel from "@arcgis/core/widgets/Sketch/SketchViewModel";
import { environment } from './../../../environments/environment';
import { tap } from 'rxjs/operators';
import { Injectable, ElementRef, Renderer2, RendererFactory2 } from "@angular/core";
import MapView from '@arcgis/core/views/MapView';
import Map from '@arcgis/core/Map';
import esriConfig  from '@arcgis/core/config';
import Zoom from '@arcgis/core/widgets/Zoom';
import Search from '@arcgis/core/widgets/Search';
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";
import { Observable, ReplaySubject } from 'rxjs';
import Graphic from '@arcgis/core/Graphic';
import Point from '@arcgis/core/geometry/Point';
import TextSymbol from '@arcgis/core/symbols/TextSymbol';
import { Store } from '@ngxs/store';
import { MapActions } from 'src/app/store/actions/map.actions';
import { DrawService } from './map-header/top-right-card/draw/draw.service';
import { GraphicFactory } from './map-header/top-right-card/draw/draw-factory.service';

esriConfig.apiKey = environment.arcgis.apiKey;
// esriConfig.request.trustedServers!.push('https://tileserver.4umaps.com/*');
// esriConfig.request.proxyUrl = 'https://rocky-harbor-08048.herokuapp.com/';
// urlUtils.addProxyRule({
//   urlPrefix: "https://tileserver.4umaps.com/",
//   proxyUrl: 'https://rocky-harbor-08048.herokuapp.com'
// });
esriConfig.request.interceptors?.push({
  before: (params)=>{
    if(params.url.includes('https://tileserver.4umaps.com')){
      params.url = 'https://rocky-harbor-08048.herokuapp.com/' + params.url;
    }
  },
  error: (error)=>{

  }
})
@Injectable({
  providedIn: 'root' //DashboardModule
})

export class MapFactoryService{
  public bcnMap!: Map;
  public mapView!: MapView;
  private renderer!: Renderer2;
  public mapViewContainer!: HTMLDivElement;
  public mapViewSubject!: ReplaySubject<MapView> //main mapview
  public mainGraphics!: GraphicsLayer;
  public drawGraphics!: GraphicsLayer;
  public labelGraphics!: GraphicsLayer;
  private zoom!: Zoom;
  public search!: Search | null;
  public sketch!: SketchViewModel;

  constructor(
    private rendererFactory: RendererFactory2,
    private sharedService: SharedService,
    private store: Store) {
    this.mapViewSubject = new ReplaySubject<MapView>(1);
  }

  public getMapView(): Observable<MapView> {
    return this.mapViewSubject.asObservable();
  }

  public clearActiveTrip(){
    this.store.dispatch([new UIActions.SetActiveTrip(null),new DrawActions.SetIsDrawingUpdated(false)]);
    this.resetGraphics();
  }

  public initializeMapView(elementRef: ElementRef, bcnMap: Map): MapView {
    this.createMapViewContainer(elementRef);
    this.createMap(bcnMap);
    this.createMapView();
    this.createGraphicsLayer();
    this.createSketchModel();
    this.createWidgets();
    this.mapViewSubject.next(this.mapView);
    return this.mapView;
  }

  public createSearch(){
    return this.mapViewSubject.pipe(
      tap((mapView)=>{
        if(mapView !== null || mapView !== undefined){
          if(this.search!== null){
            this.search = new Search({
              view: this.mapView,
              container: "searchWidgetHeader"
            })
          }
        }
      })
    )

  }

  public destroySearch(){
    this.search = null;
  }

  private createWidgets(){
    if(this.zoom == null){
      this.zoom = <Zoom>this.mapView.ui.find('zoom');
      this.mapView.ui.move(this.zoom,{
        position:"bottom-right"
      })
    }
  }

  private createGraphicsLayer(){
    if(this.drawGraphics == null){
      this.drawGraphics = new GraphicsLayer({
        id: 'drawGraphics'
      });
    }
    if (this.mainGraphics == null) {
      this.mainGraphics = new GraphicsLayer({
        id: 'mainGraphics'
      });
    }
    if (this.labelGraphics == null) {
      this.labelGraphics = new GraphicsLayer({
        id: 'labelGraphics'
      });
    }

    this.bcnMap.addMany([this.drawGraphics, this.mainGraphics, this.labelGraphics]);
  }

  private createSketchModel(){
    if(this.sketch == null){
      const polygonSymbol = new SimpleFillSymbol({
          color: [0,0,0,0.1],
          style: "solid",
          outline: {
            color: "#000000",
            width: "3px"
          }
        })
      this.sketch = new SketchViewModel({
        view: this.mapView,
        layer: this.mainGraphics,
        polygonSymbol: polygonSymbol,
        defaultUpdateOptions:{
          tool: 'reshape'
        }
        // pointSymbol: geometrySymbol
      })
      this.sketch.on("update",(evt)=>{
      })
      this.sketch.on("create", (evt) => {
        if(evt.state === 'complete'){
          this.addLabel(evt.graphic);
          this.sharedService.doneDrawingUI();
          this.updateGraphicAction(evt.graphic);
          // this.sketch.update(evt.graphic,{
          //   tool: "transform",
          //   enableRotation: false,
          //   enableScaling: true,
          //   preserveAspectRatio: true,
          //   toggleToolOnClick: false
          // });
        }
      });
      this.sketch.on("delete", (evtDel) => {
        this.labelGraphics.remove(this.labelGraphics.graphics.find((graphic)=> graphic.get("uid") === evtDel.graphics[0].get("labelUid")));
         this.store.dispatch([
          new MapActions.DeleteMainGraphic(evtDel.graphics[0].get("uid")),
          new DrawActions.SetGeometry({type: null, uid: null}),
          new UIActions.SetUI(UI.ISGEOMETRYLOADING, true)
            ]);
      })
    }
  }

  updateGraphicAction(mainGraphic: Graphic){
    const mainGraphicData = GraphicFactory.initializeGraphic(mainGraphic)!;
    this.store.dispatch(new MapActions.UpdateMainGraphics(mainGraphicData));
  }

  private createMapViewContainer(elementRef: ElementRef): void {
    if (elementRef == null) { return; }
    if (this.mapViewContainer == null) {
      this.mapViewContainer = document.createElement('div');
      this.mapViewContainer.style.cssText = 'height: 100%';
    }
    this.initializeRenderer();
    this.renderer.appendChild(elementRef.nativeElement, this.mapViewContainer);
  }

  private createMap(bcnMap: Map): void {
    if (this.bcnMap == null) {
      this.bcnMap = bcnMap;
    }
  }

  private createMapView(): void {
    if (this.mapView == null) {
      this.mapView = new MapView(
        {
          container: this.mapViewContainer,
          map: this.bcnMap,
        }
      );
    }
  }

  private initializeRenderer(): void {
    if (this.renderer == null) {
      this.renderer = this.rendererFactory.createRenderer(null, null);
    }
  }

  public removeMapViewContainer(elementRef: ElementRef): void {
    this.initializeRenderer();
    this.renderer.removeChild(elementRef.nativeElement, this.mapViewContainer);
  }

  public resetGraphics(){
    if(this.drawGraphics)  this.drawGraphics.removeAll();
    if(this.labelGraphics) this.labelGraphics.removeAll();
    if(this.mainGraphics) this.mainGraphics.removeAll();
  }

  public addLabel(mainGraphic: Graphic | any){
    let textSymbol!: any;
    let labelGraphic!: any;
    let point!: any;
   switch (mainGraphic.geometry.type) {
      case Geometries.POINT:
      case Geometries.MULTIPOINT:
       textSymbol = new TextSymbol({
         color: '#000000',
         text: mainGraphic.text ? mainGraphic.text : '',
         yoffset: 12,
         font: {
           size: 12,
           family: 'Arial',
           style: 'normal',
           weight: 'normal'
         }
       })
        labelGraphic = new Graphic({
          geometry: mainGraphic.geometry,
          symbol: textSymbol
        })
        break;
      case Geometries.POLYLINE:
        // get midpoint
        let currentPoint = mainGraphic.geometry.paths[0][this.sharedService.getMidPoint(mainGraphic.geometry.paths[0].length)];
        textSymbol = new TextSymbol({
         color: '#000000',
         text: mainGraphic.text ? mainGraphic.text : '',
         yoffset: 0,
         font: {
           size: 12,
           family: 'Arial',
           style: 'normal',
           weight: 'normal'
         }
       })
        point = new Point({
         x: currentPoint[0],
         y: currentPoint[1],
         spatialReference: this.mapView.spatialReference
       })
       labelGraphic = new Graphic({
         geometry: point,
         symbol: textSymbol
       })
        break;
      case Geometries.POLYGON:
      case Geometries.CIRCLE:
      case Geometries.RECTANGLE:
       textSymbol = new TextSymbol({
         color: '#000000',
         text: mainGraphic.text ? mainGraphic.text : '',
         yoffset: 0,
         font: {
           size: 12,
           family: 'Arial',
           style: 'normal',
           weight: 'normal'
         }
       })
        point = new Point({
         x: mainGraphic.geometry.centroid.x,
         y: mainGraphic.geometry.centroid.y,
         spatialReference: this.mapView.spatialReference
       })
       labelGraphic = new Graphic({
         geometry: point,
         symbol: textSymbol
       })
       break;
      default:
        break;
    }

   labelGraphic.graphicUid = mainGraphic.get("uid");
   labelGraphic.graphicType = mainGraphic.geometry.get("type");

   mainGraphic.text = labelGraphic.symbol.get("text");
    // graphic.desc = labelGraphic.symbol.get("desc");
   mainGraphic.labelUid = labelGraphic.get("uid");
    this.labelGraphics.add(labelGraphic);
  }

  public removeLabel(mainGraphic: any, labelGraphic: Graphic){
  //delete label graphic
  this.labelGraphics.remove(labelGraphic)
  this.addLabel(mainGraphic)
  }
}
