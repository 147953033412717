import { Component, Inject } from "@angular/core";
import { MAT_SNACK_BAR_DATA, MatSnackBarRef   } from '@angular/material/snack-bar';


@Component({
  selector: 'bcnavxe-snackbar',
  template: `
  <div>
  <p>{{ data.message }}</p>
    <button
    (click)="snackBarRef.dismiss()">{{ data.action }}</button>
  </div>
  `,
  styles: [`
  div{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  button{
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
    background: white;
    font-size: 14px;
    color: black;
    border-radius: 3px;
    padding: 5px 20px;
    font-family: 'Poppins';
    border: none;
  }
  `]
})

export class BcnavxeSnackbarComponent{
  constructor(
    public snackBarRef: MatSnackBarRef<BcnavxeSnackbarComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: {message: string, action: string}) { }
}