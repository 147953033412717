import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sort'
})
export class SortPipe implements PipeTransform {

  transform(value: any[], sort: number, propName: string) {
    if(value.length <= 0){
      return value;
    }
    return value.sort((a, b) => {
      return (a[propName] - b[propName]) * sort;
    })
  }

}
