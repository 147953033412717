export const BaseMapActionTypes = {
  GetBaseMaps: '[BASEMAPS] GetBaseMaps',
  UpdateFavoriteBaseMap: '[BASEMAPS] UpdateFavoriteBaseMap',
  Clear: '[BASEMAPS] Clear'
} as const;


export namespace BaseMapActions{

  export class GetBaseMaps {
    static readonly type = BaseMapActionTypes.GetBaseMaps;
  }


  export class UpdateFavoriteBaseMap {
    static readonly type = BaseMapActionTypes.UpdateFavoriteBaseMap;
    constructor(public payload: {data: any, name: string, isAdded: boolean}) { }
  }

  export class Clear {
    static readonly type = BaseMapActionTypes.Clear;
  }

}
