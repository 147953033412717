import { AuthState } from './../../store/state/auth.state';
import { AuthActions } from './../../store/actions/auth.actions';
import { Store } from '@ngxs/store';
import { APIRoutes } from './../constants/api_routes';
import { SignUpInterface, LoginInterface, AuthInterface } from '../models/auth.interface';
import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class HttpAuthService {
  // token = new BehaviorSubject<any>(null);
  // refreshToken = new BehaviorSubject<any>(null);
  constructor(private http: HttpClient,
    private store: Store){}

  signup(data: SignUpInterface): Observable<AuthInterface>{
    return this.http.post<AuthInterface>(APIRoutes.registerUser, data)
  }

  login(data: LoginInterface): Observable<AuthInterface>{
    return this.http.post<AuthInterface>(APIRoutes.login,data)
  }

  forgotPassword(email: string){
    const data = {
      username: email
    }
    return this.http.post(APIRoutes.ForgotPassword, data);
  }

  getNewAccessToken() {
    const tokenData = JSON.parse(<string>localStorage.getItem('_access_token'));
    const refreshToken = JSON.parse(<string>localStorage.getItem('_refresh_token'));
    this.store.dispatch(new AuthActions.UpdateAccessToken(tokenData));
    this.store.dispatch(new AuthActions.UpdateRefreshToken(refreshToken));
    return this.http.post(APIRoutes.refreshToken, { refreshtoken: refreshToken, accesstoken: tokenData}).pipe(
      map((response: any)=>{
        return {accessToken: response.payload.accessToken, refreshToken: response.payload.refreshToken}
      })
    )
  }

  logout() {
    const refreshToken = this.store.selectSnapshot(AuthState.getRefreshToken);
    return this.http.post(APIRoutes.logout, { refreshtoken: refreshToken })
  }



}
