import { Observable } from 'rxjs';
import { NoHeaderRoutes } from './shared/constants/no_header_routes';
import { SharedService } from './shared/services/shared.service';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { HttpAuthService } from './shared/services/http-auth.service';
import { LandingService } from './landing/landing-page/landing.service';
import { Component, OnInit } from '@angular/core';
import * as AOS from 'aos';
import { map, take } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { Store } from '@ngxs/store';
import { AuthActions } from './store/actions/auth.actions';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  isLoading!: boolean;
  title = 'frontend-bcnavxe';
  currentRoute = new BehaviorSubject<any>(null);
  noHeaderRoutes: string[] = NoHeaderRoutes;

  constructor(private landingService: LandingService, private store: Store, private router: Router, private sharedService: SharedService){
    this.sharedService.routeCheck().pipe(
      map((nav: any) => {
        this.currentRoute.next(nav.urlAfterRedirects);
      })
    ).subscribe()

    this.router.events
    .subscribe((val) => {
      if (val instanceof NavigationStart) {
        this.isLoading = true;
        // check if url in
        const checkIfDashboard = val.url === '/dashboard'
        if(checkIfDashboard){
          this.resizeUIComponents();
        }

      }
      if (val instanceof NavigationEnd) {
        this.isLoading = false;
        // this.currentRoute = val.urlAfterRedirects;
      }
    });
  }

  resizeUIComponents(){
    document.body.id = 'app-body'
  }

  ngOnInit(){
   AOS.init();
   this.store.dispatch(new AuthActions.AutoLogin());
   this.checkIfLoaded();
   this.logoutAllTabs();
  }

  logoutAllTabs(){
    window.addEventListener('storage', (event) => {
      if (event.storageArea == localStorage) {
        let token = localStorage.getItem('_access_token');
        if(token == undefined) {
            this.router.navigate(['/']);
            setTimeout(()=>{window.location.reload();},500)
        }else{
          // login
          window.location.reload();
        }
      }
    }, false);
  }

  checkIfLoaded() {
    window.addEventListener("load", () => {
      this.landingService.isLoading.next(true);
    });
  }

  checkhasHeader(): Observable<boolean>{
  return this.currentRoute.pipe(
    take(1),
    map((url)=>{
      if(url!==null){
        const currentRoute = url.split('?')[0];
        if (!this.noHeaderRoutes.includes(currentRoute)) {
          return true;
        }
      }
    return false;
    })
  )
  }

}
