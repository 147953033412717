import { environment } from 'src/environments/environment';
import { UserManualInterface } from './user-manual.interface';
export const USERMANUAL_ANDROID: UserManualInterface[] = [
    {title: 'Introduction',key: 'intro', isDisplayed: true,contents: [
      {title: 'Overview',key: 'intro-overview',
      displays: [
        {type: 'heading-1',text: 'Overview' , icon: 'android'},
        {type: 'paragraph',text: 'Current Version: <span class="bold">2.5.1</span> (March 2024)'},
        {type: 'paragraph',text: 'This guide describes how to use <span class="bold">BackCountry Navigator XE</span> for <span class="bold">Android</span>.'},
        {type: 'paragraph', text: 'You can find more about BCNAVXE <a href="https://www.backcountrynavigator.com/bcnxe" style="text-decoration: underline; color:#7CB342" target="_blank">here</a>'},
        {type: 'paragraph',text: 'We have made an offline version of our user guide. You can do this by downloading the PDF version.'},
        {type: 'button-nav',text: 'Download it here', link: `${environment.api.url}doc/pdf/BackCountry Navigator User Manual - android.pdf`},
      ],
      contents:[]},
      {title: 'Getting Started',key: 'getting-started',
      displays: [
        {type: 'heading-1',text: 'Getting Started'},
        {type: 'paragraph',text: 'Welcome to <span class="bold">BackCountry Navigator XE!</span>'},
        {type: 'paragraph',text: 'If you already have an account, login with the same credentials you use on the backcountry navigator XE android/iOS app. You can use the same type of login (username/password, Google, Facebook, Apple) on both.'},
        {type: 'paragraph',text: 'You can use a lot of the app and preview maps online without membership. A membership is required for saving offline maps and synching trips with your online account.'},
        {type: 'paragraph',text: 'Become a member today to get the full benefits of BackCountry Navigator XE!'},
        {type: 'button-basic',text: 'More About Membership', link: '/web/user-manual/membership'},
        {type: 'paragraph',text: 'If you are new to backCountry Navigator, start <a style="text-decoration: underline; color:#7CB342" href="/#/android/user-manual/dashboard">here</a>'},
      ],
      contents:[]},
      {title: 'FAQs',key: 'faqs',
       displays: [
        {type: 'heading-1',text: 'FAQs'},
        {type: 'paragraph',text: 'Here are the most common Frequently asked questions. You can view more FAQs <a style="text-decoration: underline; color:#7CB342" href="/#/android/user-manual/faqs">here</a>.'},
        {type: 'accordion', texts: []},
        {type: 'accordion',
        texts: [
         {title: 'Can I use the web app to sync trips with BCNAV Pro?', desc: 'No. The web app is designed for BackCountry Navigator XE (BCNAVXE). But you can migrate your trips from PRO to XE.'},
         {title: 'Can I display multiple trips at once?', desc: 'You cannot do that for now. But you can combine trips if that is your goal.'},
         {title: 'How can I maximize the use of the web app?', desc: 'The web app is designed to create your trips ahead, view your trips in a larger scale, print your trips, and allows you to directly save your trip files to your computer. You will have more control of editing and visualizing your trips. We tailored fit the web app for your needs.'},
         {title: 'Where can I get discounts or promo codes for membership?', desc: 'If you are already a member, BackCountry sends the latest updates to you and inform users promotions, events and what is currently happening in the App.'},
         {title: 'I forgot my password, and I cannot access my email. What should I do?', desc: 'You can submit a support ticket at <a style="text-decoration: underline; color:#7CB342" href="https://bcnavxe.freshdesk.com/support/home">BCNAVXE HelpDesk<a>.'},
   ]},
       ],
       contents:[]},
      {title: 'How-tos',key: 'how-tos-sub',
       displays: [
         {type: 'heading-1',text: 'How-tos'},
         {type: 'paragraph',text: 'Explore our <a style="text-decoration: underline; color:#7CB342" href="/#/android/user-manual/how-tos">collection</a>  of sample tutorials that will help you navigate through our Android app.'},
         {type: 'how-to-card', texts: [
         ]}
       ],contents:[]},
      {title: 'Helpdesk',key: 'helpdesk',
       displays: [
        {type: 'heading-1',text: 'Helpdesk'},
        {type: 'paragraph',text: 'Need to contact support? Request a new feature? You can visit <a style="text-decoration: underline; color:#7CB342" href="https://bcnavxe.freshdesk.com/support/home">here</a>'},
       ],
      contents:[]},
      {title: 'BCNAVXE Mobile',key: 'bcnavxe-mobile',
      displays: [
        {type: 'heading-1',text: 'BCNAVXE Mobile'},
        {type: 'heading-2', text: 'Android'},
        {type: 'paragraph', text: 'Download our latest version of BCNAVXE for Android here:'},
        {type: 'paragraph', text: '<a style="text-decoration: underline; color:#7CB342" href="https://play.google.com/store/apps/details?id=com.crittermap.backcountrynavigator.xe" target="_blank">BackCountry Navigator XE for Android</a>'},
        {type: 'heading-2', text: 'iOS'},
        {type: 'paragraph', text: 'Download our latest version of BCNAVXE for iOS here:'},
        {type: 'paragraph', text: '<a style="text-decoration: underline; color:#7CB342" href="https://apps.apple.com/us/app/backcountry-navigator-xe/id1330376207" target="_blank">BackCountry Navigator XE for iOS</a>'},
      ],
      contents:[]},
      {title: 'Blogs',key: 'blogs',
       displays: [
        {type: 'heading-1',text: 'Recent Blogs'},
        {type: 'blog-card', texts: [
          {title: 'Thunderforest Subscription and Alternatives', tag1: 'Maps', tag2: 'Thunderforest', desc: 'As we have been warning in our menu for a year, Thunderforest has become a paid subscription map service. Fortunately, if you are...<span class="bold">Continue Reading</span>', date: 'Nov. 7, 2020', imageUrl: 'https://crittermap-bc865.firebaseapp.com/images/worldwide/thunderforest_landscape.png', link: 'https://www.backcountrynavigator.com/blog/2020/11/7/thunderforest-subscription-and-alternatives'},
          {title: 'Changes to Caltopo Maps Coming', tag1: 'Maps', tag2: 'Caltopo', desc: 'At the end of 2020, Caltopo will stop licensing its maps to other apps, like us.Fortunately, if you are using BackCountry Navigator, either...<span class="bold">Continue Reading</span>', date: 'Oct. 21, 2020', imageUrl: 'https://crittermap-bc865.firebaseapp.com/images/us_topo_maps_and_charts/caltopo_us_24k_topo_maps.jpg', link: 'https://www.backcountrynavigator.com/blog/2020/10/21/changes-to-caltopo-maps-coming'},

        ]},
       ],
        contents:[]},
  ]},
  {title: 'Dashboard', key: 'dashboard', isDisplayed: true,contents: [
    {title: 'Overview', key: 'dash-overview',
     displays: [
      {type: 'heading-1',text: 'Dashboard'},
      {type: 'paragraph',text: 'The android app welcomes you with the dashboard. The dashboard contains the map where you can view, modify or customize your trips.'},
      {type: 'paragraph',text: 'The default map view will navigate you to the center of the map (0,0) and uses the BackCountry World Map (vector) layer. You can change the default navigation by setting a home bookmark. You can find out more by checking the bookmarks section.'},
      {type: 'image-android', imageUrl: '../../../assets/images/docs/images-android/dashboard/dashboard.png'},
      {type: 'paragraph', text: 'To move the map, hold your thumb on the screen and swipe to the direction you want the map to move. To zoom in and out, you pinch with 2 fingers and move them closer (zoom in) or farther (zoom out) from each other, you can also double also double tap to zoom in, or you can click the + or - sign on the right side of the dashboard.'},
      {type: 'image-android', imageUrl: '../../../assets/images/docs/images-android/dashboard/dashboard.gif',resizeScale: 0.07},
     ],
    contents: []},
    {title: 'Map Tools', key: 'map-tools',
     displays: [
      {type: 'heading-1',text: 'Map Tools'},
      {type: 'paragraph',text: 'The map tools are located around the Dashboard. These tools will help you with your trips.'},
      {type: 'image-android-2', imageUrl: '../../../assets/images/docs/images-android/dashboard/maptools.png', imageUrl2: '../../../assets/images/docs/images-android/dashboard/sidebar.png'},
      {type: 'paragraph',text: 'Here is the list of tools you can use to help you with your outdoor navigation.'},
      {type: 'table', texts:[
        {title: 'Search', key: 'search', desc: 'The search feature will help you to find the place you want by centering/navigating it to the map. You can immediately save it as a bookmark.'},
        {title: 'Orientation Control', key: 'orientation-control', desc: 'Located at the upper right corner of the screen, can be toggled in different states.'},
        {title: 'Home', key: 'home', desc: 'When a home bookmark is set, clicking the home button will navigate you to it. This will be your default location on signin.'},
        {title: 'Quick Access', key: 'quick-access', desc: 'Allows you to quickly select your pinned maps, trips, and overlays.'},
        {title: 'Zoom ', key: 'zoom', desc: 'Tap the + and – buttons on the Map Screen to zoom the map in and out. Alternatively, you can use two fingers to pinch to zoom out or slide to zoom out.'},
        {title: 'Recenter ', key: 'recenter', desc: 'This will center you to your phone’s current location.'},
      ]},
     ],
     contents:[
    ]},
      {title: 'Search', key: 'search',
      displays:[
        {type: 'heading-1',text: 'Search'},
        {type: 'paragraph',text: 'The search feature will help you to find the place you want by centering/navigating it to the map. '},
        {type: 'image-android', imageUrl: '../../../assets/images/docs/images-android/dashboard/search.png'},
        {type: 'paragraph',text: 'Use the Search Bar on the top of the Map Screen to search for locations on the map.'},
        {type: 'paragraph',text: 'You can also enter coordinates into the Search bar to create a waypoint at a specific location.'},
        {type: 'how-to',text: 'How to search by location', link: 'h2-use-search'},
        {type: 'how-to',text: 'How to search by coordinates', link: 'h2-use-search'},
      ],
       contents:[], child: 'child-1'},
      {title: 'Orientation Control', key: 'orientation-control',
      displays:[
        {type: 'heading-1',text: 'Orientation Control'},
        {type: 'paragraph',text: 'The orientation control in the upper right-hand corner of the screen can be toggled to different states.'},
        {type: 'image-android', imageUrl: '../../../assets/images/docs/images-android/dashboard/orientation_control.png'},

        {type: 'image-with-text', imageUrl: '../../../assets/images/docs/images-android/dashboard/compass-button_v3.jpg', text: 'The map is currently showing as <span class="bold">North Up</span>. '},
        {type: 'image-android', imageUrl: '../../../assets/images/docs/images-android/dashboard/north_up.png'},

        {type: 'image-with-text',
        imageUrl: '../../../assets/images/docs/images-android/dashboard/active-compass_v2.jpg',
        pdfText: 'If you use your fingers to turn the map or move it away from your centered location, you are in Freeform mode. The image changes again to show you which way is north. You can tap the circle again to return to North Up.',
        text: 'If you use your fingers to turn the map or move it away from your centered location, you are in <span class="bold">Freeform mode</span>. The image changes again to show you which way is north. You can tap the circle again to return to <span class="bold">North Up.</span>'},
        {type: 'image-android', imageUrl: '../../../assets/images/docs/images-android/dashboard/freedom_mode.png'},

        {type: 'paragraph',
        pdfText: 'For more orientation options, you must center the map on your location by tapping the Location button. These work when the location is centered.',
        text: 'For more orientation options, you must center the map on your location by tapping the <span class="bold">Location button</span>. These work when the location is centered.'},

        {type: 'image-with-text', imageUrl: '../../../assets/images/docs/images-android/dashboard/recenter.jpg',
        pdfText: 'The Location button is used to center or recenter the map on your current location as returned by your device GPS. When centered on your location, you can still tap the orientation control to navigate in North Up mode. Pressing it again will toggle it to some other states.',
        text: 'The <span class="bold">Location button</span> is used to center or recenter the map on your current location as returned by your device GPS. When centered on your location, you can still tap the orientation control to navigate in North Up mode. Pressing it again will toggle it to some other states.'},
        {type: 'image-android', imageUrl: '../../../assets/images/docs/images-android/dashboard/center.png'},


        {type: 'image-with-text',
        pdfText: 'In Compass Direction mode, the map rotates, and the indicator moves freely to reflect the direction the device is pointing. This functionality necessitates compass hardware in the device.',
        imageUrl: '../../../assets/images/docs/images-android/dashboard/directional-compass.jpg', text: 'In <span class="bold">Compass Direction</span> mode, the map rotates. and the indicator moves freely to show you the direction the device is pointing. This requires compass hardware in the device.'},
        {type: 'image-android', imageUrl: '../../../assets/images/docs/images-android/dashboard/direction-mode.gif'},


        {type: 'image-with-text',
        pdfText: 'In Moving Direction mode, the pointer will show at the bottom of the screen, and the map rotates according to which way you are moving. From this state, you can tap the Orientation Control to return to Freeform mode, and then again to restore North Up.',
        imageUrl: '../../../assets/images/docs/images-android/dashboard/movingdirection.png', text: 'In <span class="bold">Moving Direction</span> mode, the pointer will show at the bottom of the screen, and the map rotates according to which way you are moving. From this state, you can tap the Orientation Control to return to <span class="bold">Freeform mode</span>, and then again to restore <span class="bold">North Up</span>.'},
        {type: 'image-android', imageUrl: '../../../assets/images/docs/images-android/dashboard/moving-direction.png'},
      ],
       contents:[], child: 'child-1'},
      {title: 'Quick Access', key: 'quick-access', contents:[],
      displays:[
        {type: 'heading-1',text: 'Quick Access'},
        {type: 'image-with-text', imageUrl: '../../../assets/images/docs/images-android/dashboard/quick-access-button.jpg',
         text: 'Tap the Quick Access button (on the Map Screen) to quickly select your pinned maps, trips, and overlays.',
         resizeScale: 0.35},
        {type: 'image-android', imageUrl: '../../../assets/images/docs/images-android/dashboard/quick_access_button.png'},
        {type: 'table', texts:[
          {title: 'Offline',  desc: 'Tap offline to view the maps you have downloaded to your device. This requires that you download the maps in advance. Remember! Tapping the Offline tab only views off-line maps. Go back to the Maps Menu to choose other maps for this short list'},
          {title: 'Show Compass',desc: 'Tap show compass to view a large compass centrally on the Map Screen.This compass is similar to a “Lensatic” compass and can assist you in navigating on-the-go (see image below).'},
          {title: 'Pinned Map Sources', desc: 'Pin the Maps you use most frequently. These are ones you can switch between easily.'},
          {title: 'Pinned Trips', desc: 'Pinned Trips will show on the map. Up to five may be displayed at one time.'},
          {title: 'Overlays ', desc: 'Overlays you have downloaded can also be pinned to the map, and this menu can control which of the sublayers will show.'},
        ]},
        {type: 'paragraph',text: 'The large “Lensatic” compass which can be viewed on the Map Screen.'},
        {type: 'image-android', imageUrl: '../../../assets/images/docs/images-android/dashboard/lensatic_compass.png'},
      ],
      child: 'child-1'},
      {title: 'Zoom', key: 'zoom',
      displays:[
        {type: 'heading-1',text: 'Zoom'},
        {type: 'paragraph',text: 'Tap the + and – buttons on the Map Screen to zoom the map in and out. Alternatively, you can use two fingers to pinch to zoom out or slide to zoom out. The scale of the map in the upper left-hand corner will change based on zooming in or out.'},
        {type: 'image-android', imageUrl: '../../../assets/images/docs/images-android/dashboard/zoom.png'},
      ],
      contents:[], child: 'child-1'},
      {title: 'Center', key: 'center',
      displays:[
        {type: 'heading-1',text: 'Center'},

        {type: 'image-with-text', imageUrl: '../../../assets/images/docs/images-android/dashboard/recenter.jpg', text: 'Tap at any time when using the map to recenter the map to your current location.'},
        {type: 'image-android', imageUrl: '../../../assets/images/docs/images-android/dashboard/center.png'},


        {type: 'image-with-text', imageUrl: '../../../assets/images/docs/images-android/dashboard/recenter.jpg', text: 'Hold the button to stop using the GPS and save power.'},
        {type: 'image-android', imageUrl: '../../../assets/images/docs/images-android/dashboard/save-power.gif', resizeScale: 0.35},
      ], contents:[], child: 'child-1'},
      {title: 'Actions Menu', key: 'actions-menu',
      displays:[
        {type: 'heading-1',text: 'Actions Menu'},
        {type: 'image-with-text', imageUrl: '../../../assets/images/docs/images-android/dashboard/track-menu.jpg', text: 'Tap to access the Action menu in the bottom right-hand corner of the Map Screen.'},
        {type: 'image-android', imageUrl: '../../../assets/images/docs/images-android/dashboard/action_menu.png'},

        {type: 'paragraph',text: 'You will see these option:'},
        {type: 'image-android', imageUrl: '../../../assets/images/docs/images-android/dashboard/action_menu_opened.png'},
        {type: 'table', texts:[
          {title: 'Download Map', key: 'download-map', desc: 'Allows you to download the map for offline use.'},
          {title: 'Record a track', key: 'record-track', desc: 'Allows you to record your trips using the GPS.'},
          {title: 'Draw', key: 'draw', desc: 'This feature adds graphics to your trip.'},
          {title: 'Adding waypoints', key: 'adding-waypoints', desc: 'Allows you to add points for references in your trips.'},
        ]},
      ],
      contents:[], child: 'child-1'},
        {title: 'Download Map', key: 'download-map',
        displays:[
          {type: 'heading-2',text: 'Download Map'},
          {type: 'image-android', imageUrl: '../../../assets/images/docs/images-android/dashboard/download_map.png'},
          {type: 'image-with-text', imageUrl: '../../../assets/images/docs/images-android/dashboard/download-maps.png', text: 'Tap Download Map to make maps you have access to through your membership available even when you aren’t online.'},
          {type: 'image-with-text', imageUrl: '../../../assets/images/docs/images-android/dashboard/track-menu.jpg', text: 'This is one of the options you see when you push the  in the bottom right.'},
          {type: 'note', text: 'A <a style="text-decoration: underline; color:#7CB342" href="/#/android/user-manual/dashboard/account">Membership</a> is required to use this function.'},
          {type: 'how-to',text: 'How to download a map', link: 'h2-download-blocks-from-vector'},
        ],
        contents:[], child: 'child-2'},
        {title: 'Record a track', key: 'record-track',
        displays:[
          {type: 'heading-2',text: 'Record a track'},
          {type: 'image-android', imageUrl: '../../../assets/images/docs/images-android/dashboard/record_a_track.png'},
          {type: 'paragraph',text: 'Recording a track is easy. To record a track, click the how to link below.'},
          {type: 'how-to',text: 'How to record a track', link: 'h2-record-a-track'},
        ], contents:[], child: 'child-2'},
        {title: 'Draw', key: 'draw',
        displays:[
          {type: 'heading-2',text: 'Draw'},
          {type: 'paragraph',text: 'The draw feature allows you to add different geometries to your trips. You can also customize the drawings by changing it’s color, icon, label, description, or removing a trip segment you don’t want. The small button at the bottom of the screen gives you an option of what to draw:'},
          {type: 'image-android', imageUrl: '../../../assets/images/docs/images-android/dashboard/draw_screen.png'},
          {type: 'image-with-text', imageUrl: '../../../assets/images/docs/images-android/dashboard/draw.png', text: 'Tap Draw to open the Drawing menu and add polygons, lines, and waypoints to your Trip.'},
          {type: 'heading-2',text: 'Drawing Tools'},
          {type: 'table-image-title', texts:[
            {title: '../../../assets/images/docs/images-android/dashboard/close-draw_v1.png',  desc: ' Close the draw window.'},
            {title: '../../../assets/images/docs/images-android/dashboard/clear.png',desc: 'Clear the screen.'},
            {title: '../../../assets/images/docs/images-android/dashboard/color-select.png', desc: 'Select the color for your graphic object.'},
            {title: '../../../assets/images/docs/images-android/dashboard/undo-redo.png', desc: 'Undo and Redo.'},
            {title: '../../../assets/images/docs/images-android/dashboard/download.png', desc: 'Save object.'},
          ]},
          {type: 'heading-2',text: 'Drawing Types'},
          {type: 'paragraph',text: 'Here are the list of different geometries you can use for your trips.'},
          {type: 'table', texts:[
            {title: 'Freehand Polygon',  desc: 'This is used to create a polygon by holding your thumb on the screen and move it freely.'},
            {title: 'Polygon',desc: 'This is used to create a polygon by clicking on the position you want to add your vertices.'},
            {title: 'Freehand Polyline', desc: 'This is used to create a polyline by holding your thumb on the screen and move it freely.'},
            {title: 'Polyline', desc: 'This is used to create a polygon by clicking on the position you want to add your vertices.'},
            {title: 'Multiple Points ', desc: 'Allows you to add multiple points simultaneously.'},
            {title: 'Points', desc: 'Allows you to create a single point.'},
            {title: 'Auto-Route', desc: 'Allows you to create a polyline then automatically finds a route based on the drawn polyline. List of auto-routes available: driving-car, cycling-mountain, foot-hiking, driving-hgv, cycling-regular, cylcing-road, cycling-electric, foot-walking'},
          ]},
          {type: 'how-to',text: 'How to draw a Freehand Polygon', link: 'h2-create-freehand-polygon'},
          {type: 'how-to',text: 'How to draw a Polygon', link: 'h2-create-polygon'},
          {type: 'how-to',text: 'How to draw a Freehand Polyline', link: 'h2-create-freehand-polyline'},
          {type: 'how-to',text: 'How to draw a Polyline', link: 'h2-create-polyline'},
          // {type: 'how-to',text: 'How to draw Multiple Points', link: 'h2-a-draw-multiple-points'},
          {type: 'how-to',text: 'How to draw a Point', link: 'h2-change-point-icon'},
          // {type: 'how-to',text: 'How to use Auto-Route', link: 'h2-a-draw-auto-route'},
        ],
        contents:[], child: 'child-2'},
      {title: 'Adding waypoints', key: 'adding-waypoints',
      displays: [
        {type: 'heading-2',text: 'Add new waypoint'},
        {type: 'paragraph',text: 'Way points are just a fancy name for points. It allows you to make references and landmarks on your trips.'},
        {type: 'image-android', imageUrl: '../../../assets/images/docs/images-android/dashboard/adding_waypoint.png'},
        // {type: 'how-to',text: 'How to add a waypoint', link: 'h2-a-way-point'},
      ],
      child: 'child-2', contents:[]},
      {title: 'Sidebar', key: 'sidebar',
      displays: [
        {type: 'heading-1',text: 'Sidebar'},
        {type: 'paragraph',text: 'The sidebar or main menu is located when clicking the hamburger menu on the top left beside the search bar.'},
        {type: 'image-android', imageUrl: '../../../assets/images/docs/images-android/dashboard/sidebar.png'},
        {type: 'table', texts:[
          {title: 'My Trips', key: 'my-trips', desc: 'Shows your list of trips and shared trips with you.'},
          {title: 'Map', key: 'map', desc: 'Allows you to add different layers in the map. It is divided into three. Suggested Maps, Favorite Maps, and Downloaded Maps'},
          {title: 'Bookmark', key: 'bookmark', desc: 'List of your saved bookmarks that allows you to navigate at a specific location.'},
          {title: 'Overlays', key: 'overlays', desc: 'These are addon layers that can be bought by digital currency.'},
          {title: 'Modes ', key: 'modes', desc: 'Tap to go online or offline.'},
        ]},
        // {type: 'how-to',text: 'How to add a waypoint', link: 'h2-a-way-point'},
      ],
       contents:[]},
        {title: 'My Trips', key: 'my-trips',
        displays: [
          {type: 'heading-2',text: 'My Trips'},
          {type: 'paragraph',text: 'The My Trips section is the first part of the sidebar. It shows your trips by selecting a trip folder and trips that are shared with you. You can also import gpx/kml files by clicking the export button located at the upper right of your screen '},
          {type: 'image-android', imageUrl: '../../../assets/images/docs/images-android/dashboard/my-trips.gif'},
          {type: 'how-to',text: 'How to pin a trip', link: 'h2-pin-a-trip'},
          {type: 'how-to',text: 'How to export a trip', link: 'h2-export-trip'},
          {type: 'how-to',text: 'How to upload a gpx/kml', link: 'h2-upload-kml-gpx'},
        ],
         contents:[], child: 'child-1'},
        {title: 'Map', key: 'map',
        displays: [
          {type: 'heading-2',text: 'Map'},
          {type: 'paragraph',text: 'Maps or basemap offers different map layers to apply in the map. Some map layers are available for free, based on membership or can be purchased by digital currency.'},
          {type: 'image-android', imageUrl: '../../../assets/images/docs/images-android/dashboard/maps.png'},
          {type: 'table', texts:[
            {title: 'Suggested', desc: 'Shows a list of maps BCNAVXE has prepared for you. You can search for the map name or filter those maps based on their type. Keep in mind that suggested maps only shows maps based on the current map center.'},
            {title: 'Favorite',desc: 'All maps that were marked as favorite can be seen here.'},
            {title: 'Downloaded', desc: 'List of your downloaded maps. If you choose offline mode, downloaded maps will automatically be used.'},
          ]},
          // {type: 'how-to',text: 'How to use a Map', link: 'h2-a-use-map'},
          {type: 'how-to',text: 'How to mark a Map as favorites', link: 'h2-add-maplayer-to-favorites'},
          {type: 'how-to',text: 'How to remove a Map in favorites', link: 'h2-remove-map-layer-to-favorite'},
        ],
         contents:[], child: 'child-1'},
        {title: 'Bookmark', key: 'bookmark',
        displays: [
          {type: 'heading-2',text: 'Bookmark'},
          {type: 'paragraph',text: 'Bookmarks allows you to save a specific location and go back to it anytime.'},
          {type: 'image-android', imageUrl: '../../../assets/images/docs/images-android/dashboard/bookmark.png'},
          {type: 'how-to',text: 'How to add a bookmark', link: 'h2-add-bookmark'},
          {type: 'how-to',text: 'How to rename a bookmark', link: 'h2-rename-bookmark'},
          {type: 'how-to',text: 'How to delete a bookmark', link: 'h2-delete-bookmark'},
          {type: 'how-to',text: 'How to move a bookmark to other folder', link: 'h2-a-move-bookmark'},
          {type: 'how-to',text: 'How to use a bookmark', link: 'h2-a-use-bookmark'},
        ],
        contents:[], child: 'child-1'},
      {title: 'Overlays', key: 'overlays',displays: [
        {type: 'heading-2',text: 'Overlays'},
        {type: 'paragraph',text: 'Overlays are optional add-ons that add additional information that displays on top of the map. This can be bought from the web or in the app.'},
        {type: 'image-android', imageUrl: '../../../assets/images/docs/images-android/dashboard/overlays.png'},
        {type: 'how-to',text: 'How to buy an overlay', link: 'h2-buy-overlay'},
        {type: 'how-to',text: 'How to use an overlay', link: 'h2-use-overlay'},
      ], contents:[], child: 'child-1' },
      {title: 'Modes', key: 'modes', displays: [
        {type: 'heading-2',text: 'Modes'},
        {type: 'paragraph',text: 'There are 2 modes in the app, online and offline. You can change the mode by tapping on the cloud icon.'},
        {type: 'image-android-2', imageUrl: '../../../assets/images/docs/images-android/dashboard/modes.png', imageUrl2: '../../../assets/images/docs/images-android/dashboard/offline.png'},
        {type: 'table', texts:[
          {title: 'Online',  desc: 'If it is switched to Online, maps coming from the server is being used.'},
          {title: 'Offline',desc: 'Automatically switches the map to your downloaded maps instead of maps that are coming from the server. If there are no downloaded maps present, a grid will be displayed.'},
        ]},
      ], contents:[],child: 'child-1'},
  ]},
  {title: 'Account', key: 'account',isDisplayed: true, contents: [
    {title: 'Overview', key: 'mem-overview',
    displays: [
      {type: 'heading-1',text: 'Account'},
      {type: 'paragraph',text: 'You can see your User Information in the Account Page. You can Upgrade Membership, Change Password, Buy Digital Coins and Update your Personal Information here.'},
      {type: 'image-android-2', imageUrl: '../../../assets/images/docs/images-android/account/overview.png'},
    ],
    contents: []},
    {title: 'Basic Information', key: 'basic-information',
    displays: [
    {type: 'heading-1',text: 'Basic Information'},
    {type: 'paragraph',text: 'On the basic information page, you can update your profile here. You can change your first name and last name. '},
    {type: 'note', text: 'Take note that you cannot change your email once you have signed up.'},
    {type: 'image-android-2', imageUrl: '../../../assets/images/docs/images-android/account/basic_information.png'},
  ],
    contents: []},
    {title: 'Change Password', key: 'change-password',
    displays: [
    {type: 'heading-1',text: 'Change Password'},
    {type: 'paragraph',text: 'If you want to change your password, enter your current password and your new password, then click save. Once done, you can try logging in again and try your new password.'},
    {type: 'image-android-2', imageUrl: '../../../assets/images/docs/images-android/account/change_password.png'},
  ],
    contents: []},
    {title: 'Membership', key: 'membership',
    displays: [
    {type: 'heading-1',text: 'Membership'},
    {type: 'paragraph',text: 'On the membership page, you can check how many days left of your current membership. If you want to upgrade, you can choose a gold, silver or bronze membership. For more information about membership types, visit our web page.'},
    {type: 'note', text: 'Note that you cannot downgrade to a lower tier membership once you have purchased a membership.'},
    {type: 'image-android-2', imageUrl: '../../../assets/images/docs/images-android/account/membership.png'},
  ],
    contents: []},
    {title: 'My Wallet', key: 'my-wallet',
    displays: [
    {type: 'heading-1',text: 'My Wallet'},
    {type: 'paragraph',text: 'Shows how many coins you have. You can also buy more coins here. The coins are used to purchase addons for your maps.'},
    {type: 'image-android-2', imageUrl: '../../../assets/images/docs/images-android/account/my_wallet.png'},
  ],
    contents: []},
  {title: 'Logout', key: 'logout',
    displays: [
    {type: 'heading-1',text: 'Logout'},
    {type: 'paragraph',text: 'The logout button will sign off your current account. Logging out will close all your trips and unsaved trips so save your trips first.'},
    {type: 'image-android-2', imageUrl: '../../../assets/images/docs/images-android/account/logout.png'},
  ],
    contents: []},
  ]},
  {title: 'Settings', key: 'settings',isDisplayed: true, contents: [
    {title: 'Overview', key: 'overview', displays: [
      {type: 'heading-1',text: 'Overview'},
      {type: 'paragraph',text: 'The settings screen allows you to modify your waypoints, tracks and trip view, change your map storage, adjust gps sample rate, allow map rotation, change compass style, change unit formats.'},
    {type: 'image-android-2', imageUrl: '../../../assets/images/docs/images-android/settings/overview.gif'},
    ], contents: []},
    {title: 'Home Screen', key: 'home-screen', displays: [
      {type: 'heading-1',text: 'Home Screen'},
      {type: 'paragraph',text: 'The home screen settings shows a list of options which widget/tool you want to see.'},
    {type: 'image-android-2', imageUrl: '../../../assets/images/docs/images-android/settings/home_screen.png'},
    {type: 'table', texts:[
      {title: 'Full Screen Mode', desc: 'Toggle to change to full screen.'},
      {title: 'Scale bar / number',desc: 'Located at the upper left of the dashboard, under the search bar. Provides you a visual inidication of distance and feature size on the map.'},
      {title: 'Statistics', desc: 'Show/hide the liist of trip statistics'},
      {title: 'Quick Access', desc: 'Located at the right, above the zoom buttons. These are shortcuts that enable you to open the settings quicker.'},
      {title: 'Zoom', desc: 'When enabled, shows the zoom in and out button at the right side of the screen.'},
      {title: 'Keep Screen On', desc: 'When going to the home page of your android app. Backcountry Navigator app will still run and show at the lower right of the screen.'},
    ]},
    ], contents: []},
    {title: 'Map Storage', key: 'map-storage', displays: [
      {type: 'heading-1',text: 'Map Storage'},
      {type: 'paragraph',text: 'You can choose where to store and get your trips by picking Internal or SD card in Map Storage. It also shows how much storage the app has used.'},
    {type: 'image-android-2', imageUrl: '../../../assets/images/docs/images-android/settings/map_storage.png'},
    ], contents: []},
    {title: 'GPS', key: 'gps', displays: [
      {type: 'heading-1',text: 'GPS'},
      {type: 'paragraph',text: "The GPS Sample rate allows you to configure how frequently your device's chipset communicates with satellites to determine the device's location."},
    {type: 'image-android-2', imageUrl: '../../../assets/images/docs/images-android/settings/gps.png'},
    {type: 'note', text: "When set to 1, it means the system checks every 1 second. Therefore, the higher the number, the more frequently it checks. While this enhances accuracy, it also accelerates battery drain on your device."},
    ], contents: []},
    {title: 'Map Controls', key: 'map-controls', displays: [
      {type: 'heading-1',text: 'Map Controls'},
      {type: 'paragraph',text: "The map control options allows you to set what kind of controls you can do to the map."},
    {type: 'image-android-2', imageUrl: '../../../assets/images/docs/images-android/settings/map_controls.png'},
    {type: 'table', texts:[
      {title: 'Allow map rotation', desc: 'When enabled, use your 2 fingers and twist the screen to the direction you want to rotate your map.'},
    ]},
    ], contents: []},
    {title: 'Compass', key: 'compass', displays: [
      {type: 'heading-1',text: 'Compass'},
      {type: 'paragraph',text: "List of settings for the compass."},
      {type: 'image-android-2', imageUrl: '../../../assets/images/docs/images-android/settings/compass.png'},
      {type: 'table', texts:[
        {title: 'Compass Style', desc: 'You can change the style of your compass to "Orienteering" or "Bearing" compass.'},
        {title: 'Sensors for Compass', desc: 'You can change the sensors of your compass to "Rotation Vector", "Magnetic and Accelerometer", "Magnetic and Gravity" or "Orientation".'},
      ]},
    ], contents: []},
    {title: 'Unit Format', key: 'unit-format', displays: [
      {type: 'heading-1',text: 'Unit Format'},
      {type: 'paragraph',text: "You can change the format of your measurement units here."},
      {type: 'image-android-2', imageUrl: '../../../assets/images/docs/images-android/settings/unit_format.png'},
      {type: 'table', texts:[
        {title: 'Area', desc: 'You can choose "Hectares" or "Acres".'},
        {title: 'Distance', desc: 'You can choose "Miles" or "Kilometers".'},
        {title: 'Coordinates', desc: 'You can choose "Decimal Degrees", "Degrees and Minutes", "Degree and Minutes and Seconds" ,"UTM", "MGRS".'},
      ]},
    ], contents: []},
    {title: 'Waypoint Options', key: 'waypoint-options', displays: [
      {type: 'heading-1',text: 'Waypoint Options'},
      {type: 'paragraph',text: "These are the settings for waypoint options."},
      {type: 'image-android-2', imageUrl: '../../../assets/images/docs/images-android/settings/waypoint_options.png'},
      {type: 'table', texts:[
        {title: 'Waypoint Name', desc: 'The format for waypoint name ("HH:MM[07-30]","MM-DD HH:MM [12-24 14:30]", "MM-DD-YY HH:MM:SS [12-24-19 14:30:30]" ).'},
        {title: 'Waypoint Name Prefix', desc: 'Adds waypoint name prefix before the waypoint name.'},
        {title: 'Waypoint Icon Size', desc: 'Sets the size of the waypoint icon.'},
      ]},
    ], contents: []},
    {title: 'Track Options', key: 'track-options', displays: [
      {type: 'heading-1',text: 'Track Options'},
      {type: 'paragraph',text: "These are the settings for track options."},
      {type: 'image-android-2', imageUrl: '../../../assets/images/docs/images-android/settings/track_options.png'},
      {type: 'table', texts:[
        {title: 'Track Name', desc: 'The format for track name ("YYMMMDD [19OCT01]", "MMDMDD-HHMM [OCT24-0755]", "YYMMMDD-HHMMSS [19OCT01-075525]" ).'},
        {title: 'Track Name Prefix', desc: 'Adds track name prefix before the track name.'},
      ]},
    ], contents: []},
    {title: 'Trip Options', key: 'trip-options', displays: [
      {type: 'heading-1',text: 'Trip Options'},
      {type: 'paragraph',text: "These are the settings for trip options."},
      {type: 'image-android-2', imageUrl: '../../../assets/images/docs/images-android/settings/trip_options.png'},
      {type: 'table', texts:[
        {title: 'Trip Name', desc: 'The format for trip name ("YYMMMDD [19OCT01]", "MMDMDD-HHMM [OCT24-0755]", "YYMMMDD-HHMMSS [19OCT01-075525]" ).'},
        {title: 'Trip Name Prefix', desc: 'Adds trip name prefix before the trip name.'},
      ]},
    ], contents: []},
    {title: 'Other', key: 'other', displays: [
      {type: 'heading-1',text: 'Other'},
      {type: 'image-android-2', imageUrl: '../../../assets/images/docs/images-android/settings/other.png'},
      {type: 'table', texts:[
        {title: 'APRSDroid Display', desc: 'Allows reporting your position to the (Automatic Packet Reporting System) network when your location is clicked.'},
        {title: 'Activity Recognition', desc: 'When enabled, the app will determine the moving time when a track is being recorded.'},
      ]},
    ], contents: []},
  ]},
  {title: 'Help', key: 'help',isDisplayed: true, contents: [
    {title: 'Overview', key: 'help-overview'
    ,displays: [
      {type: 'heading-1',text: 'Help'},
      {type: 'paragraph',text: 'The help button redirects you to <a style="text-decoration: underline; color:#7CB342" href="https://bcnavxe.freshdesk.com/support/home"> https://bcnavxe.freshdesk.com/support/home.</a>'},
      {type: 'image', imageUrl: '../../../assets/images/docs/images/help/help.png'},
      {type: 'paragraph',text: 'This is the BCNAVXE helpdesk, where you can submit feature requests, find tips and tricks for both mobile and web, stay informed with the latest news, and report any issues you encounter by submitting a ticket.'},
      {type: 'image', imageUrl: '../../../assets/images/docs/images/help/helpdesk.png'},
    ],
    contents: []},
  ]},
  {title: 'How-tos', key: 'how-tos',isDisplayed: true, contents: [
    {title: 'How Tos', key: 'how-tos-sub',displays: [
      {type: 'heading-1',text: 'How Tos'},
      {type: 'paragraph',text: 'Here are our list of how tos.'},
      {type: 'search', text: 'Enter keyword'},
      {type: 'how-to-card', texts: [
        ]}
    ], contents:[]},
  ]},
  {title: 'FAQs', key: 'faqs', isDisplayed: true,contents: [
    {title: 'FAQs', key: 'faqs', displays: [
      {type: 'heading-1',text: 'Frequently Asked Questions'},
      {type: 'accordion',
      texts: [
        {title: 'Can I use the web app to sync trips with BCNAV Pro?',
        pdfText: 'No. The web app is designed for BackCountry Navigator XE (BCNAVXE). But you can migrate your trips from PRO to XE.',
        desc: '<span class="bold">A: </span>No. The web app is designed for BackCountry Navigator XE (BCNAVXE). But you can migrate your trips from PRO to XE.'},
        {title: 'Can I display multiple trips at once?',
        pdfText: 'You cannot do that for now. But you can combine trips if that is your goal.',
        desc: '<span class="bold">A: </span>You cannot do that for now. But you can combine trips if that is your goal.'},
        {title: 'How can I maximize the use of the web app?',
        pdfText: 'The web app is designed to create your trips ahead, view your trips in a larger scale, print your trips, and allows you to directly save your trip files to your computer. You will have more control of editing and visualizing your trips. We tailored fit the web app for your needs.',
        desc: '<span class="bold">A: </span>The web app is designed to create your trips ahead, view your trips in a larger scale, print your trips, and allows you to directly save your trip files to your computer. You will have more control of editing and visualizing your trips. We tailored fit the web app for your needs.'},
        {title: 'Where can I get discounts or promo codes for membership?',
        pdfText: 'If you are already a member, BackCountry sends the latest updates to you and inform users promotions, events and what is currently happening in the App.',
        desc: '<span class="bold">A: </span>If you are already a member, BackCountry sends the latest updates to you and inform users promotions, events and what is currently happening in the App.'},
        {title: 'I forgot my password, and I cannot access my email. What should I do?',
        pdfText: 'You can submit a support ticket at BCNAVXE HelpDesk.',
        desc: '<span class="bold">A: </span>You can submit a support ticket at <a style="text-decoration: underline; color:#7CB342" href="https://bcnavxe.freshdesk.com/support/home">BCNAVXE HelpDesk<a>.'},
        {title: 'What is the difference between Bronze, Silver ang Gold Plans?',
        pdfText: 'You can check at the pricing plans page.',
        desc: '<span class="bold">A: </span>You can check at the pricing plans <a style="text-decoration: underline; color:#7CB342" href="https://bcnavxe.com/#/pricing-plans">page<a>.'},
        {title: 'Is there a limit in uploading trips?',
        pdfText: 'Yes. We recommend files not exceeding 20MB as uploading files takes a lot of our storage. But you can upload as many trips as you want.',
        desc: '<span class="bold">A: </span>Yes. We recommend files not exceeding 20MB as uploading files takes a lot of our storage. But you can upload as many trips as you want.'},
        {title: 'Can I see other people\'s trips?',
        pdfText: 'Yes. As long as you have the link and it is set to public.',
        desc: '<span class="bold">A: </span>Yes. As long as you have the link and it is set to public.'},
        {title: 'Can I import all of my trips at once?',
        pdfText: 'As of now, you can only export 1 trip at a time to the web.',
        desc: '<span class="bold">A: </span>As of now, you can only export 1 trip at a time to the web.'},
        {title: 'I want a custom Map Layer. Is it possible to use other map layers?',
        pdfText: 'You can only select our preset map layers for now. You can submit a request at the BCNAVXE Helpdesk.',
        desc: '<span class="bold">A: </span>You can only select our preset map layers for now. You can submit a request at the BCNAVXE Helpdesk.'},
        {title: 'Say I want to go at X location, can the app generate a way for me?',
        pdfText: 'Yes. You can use the Autorute feature.',
        desc: '<span class="bold">A: </span>Yes. You can use the Autorute feature.'},
        {title: 'I want to use a different unit of measure, is it possible to change from miles to kilometers or other units?',
        pdfText: 'Yes. Go to User Profile and select the measurement you want.',
        desc: '<span class="bold">A: </span>Yes. Go to User Profile and select the measurement you want.'},
        {title: 'Is there a pdf version of this?',
        pdfText: 'Yes. You are currently reading it.',
        desc: 'Yes.'
        },
        {title: 'Is there a Manual for the Mobile Version?',
        pdfText: 'Yes. You can find it in the BCNAVXE Web App.',
        desc: '<span class="bold">A: </span>Yes. You can find it <a style="text-decoration: underline; color:#7CB342" href="https://www.manula.com/manuals/crittermap/backcountry-navigator-xe/1/en/topic/getting-started">here<a>.'},
  ]},
    ], contents: []}
  ]},
  {title: 'Directions', key: 'directions', isDisplayed: false, contents:[]}

]
