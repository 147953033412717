import { Directive, ElementRef, Inject, Input, PLATFORM_ID } from '@angular/core';

@Directive({
  selector: '[appDropdown]',
  exportAs: 'appDropdown'
})
export class DropdownDirective {
@Input() appDropdown = ''
  constructor(private el: ElementRef, @Inject(PLATFORM_ID) private platformId: any) { }

  private get element(): HTMLElement{
    return this.el.nativeElement;
  }

  toggle(force?: boolean){
    this.element.classList.toggle(this.appDropdown, force)
  }

  close():void{
    this.toggle(false);
  }
  open(): void {
    this.toggle(true);
  }
}
