import { geoJSON } from "src/app/shared/models/geoJSON.model";

export const TripActionTypes = {
  GetTrips: '[TRIP] GetTrips',
  UpsertTrip: '[TRIP] UpsertTrip',
  DeleteTrip: '[TRIP] DeleteTrip',
  Clear: '[TRIP] Clear',
} as const;







export namespace TripActions{
  export class GetTrips {
    static readonly type = TripActionTypes.GetTrips
  }

  export class UpsertTrip {
    static readonly type = TripActionTypes.UpsertTrip
    constructor(public payload: FormData){}
  }

  export class Clear {
    static readonly type = TripActionTypes.Clear;
  }

  export class DeleteTrip{
    static readonly type = TripActionTypes.DeleteTrip;
    constructor(public payload: string){}
  }
}
