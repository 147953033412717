<div class="logo-loading-container">
  <div class="logo-image-container avatar">
    <img src="../../../../assets/images/logo/xe_logo.png" alt="">
  </div>
  <div class="progress-bar-container">
    <h2>Loading File...</h2>
    <mat-progress-bar
  class="example-margin"
  color="primary"
  mode="indeterminate"
  >
  </mat-progress-bar>
  </div>

</div>

