<div class="main-container">
  <div class="not-found-container">
    <ng-container *ngIf="successData.status === 'success'">
      <h3>{{successData.type === 'update' ? '' : 'Payment Successful!'}}</h3>
      <ng-container *ngIf="successData.type === 'coins'">
      <h4 >You have purchased {{successData.data.coins}} coins</h4>
      <p>Thank you for your business and being part of BackCountry Navigator XE.</p>
      <p> You will be redirected to the dashboard in {{counter}} seconds...</p>
      </ng-container>

      <ng-container *ngIf="successData.type === 'membership'">
      <h4 >You have purchased {{successData.data.membershipName}} membership</h4>
      <p>Thank you for your business and being part of BackCountry Navigator XE.</p>
      <p> You will be redirected to the dashboard in {{counter}} seconds...</p>
      </ng-container>

      <ng-container *ngIf="successData.type === 'update'">
      <h4 >You have successfully updated your billing info!</h4>
      <p> You will be redirected to the dashboard in {{counter}} seconds...</p>
      </ng-container>


    </ng-container>


  </div>
</div>
