<div *ngIf="serviceStatus?.type === 'loading' || serviceStatus?.type === 'error'">

    <div *ngIf="serviceStatus?.type === 'loading'" style="height: 100%; width: 100%;background: #F8F8F8;">
        <!-- <span class="spinner spinner-large"></span> -->
    </div>

    <div *ngIf="serviceStatus?.type === 'error'">
        <!-- Error Message -->
    </div>

</div>

<ng-content *ngIf="serviceStatus?.type === 'content'"></ng-content>