import { UploadRoute } from './shared/constants/upload_routes';
import { AuthState } from './store/state/auth.state';
import { Store , Select} from '@ngxs/store';
import { newAccessToken } from './shared/models/access-token';
import { UntokenizedRoutes } from './shared/constants/untokenized_routes';
import { HttpAuthService } from './shared/services/http-auth.service';
import { environment } from 'src/environments/environment';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { exhaustMap, take, catchError, switchMap, filter } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AuthActions } from './store/actions/auth.actions';

@Injectable()
export class AuthInterceptorService implements HttpInterceptor{
  untokenizedRoutes: string[] = UntokenizedRoutes;
  isRefreshing: boolean = false;
  constructor(private httpAuthService: HttpAuthService,
              private store: Store){}
  @Select(AuthState.getAccessToken) accessToken$!: Observable<string>;
  intercept(req: HttpRequest<any>,next: HttpHandler): Observable<any>{
        return this.accessToken$.pipe(
        take(1),
        exhaustMap(token => {
          const updatedRequest = this.addToken(req, token);
          return next.handle(updatedRequest).pipe(
            catchError((error) => {
              if (error instanceof HttpErrorResponse && (error.status === 401 || error.status === 0) && !this.untokenizedRoutes.includes(req.url)) {
                return this.handle401Error(req, next)
              } else {
                return throwError(error);
              }
            })
          )
        })
      )
  }

  private handle401Error(req: HttpRequest<any>, next: HttpHandler){

    if(!this.isRefreshing){
      this.isRefreshing = true;
      this.store.dispatch(new AuthActions.UpdateAccessToken(null));
      this.store.dispatch(new AuthActions.UpdateRefreshToken(null));
      return this.httpAuthService.getNewAccessToken().pipe(
        switchMap((token: newAccessToken)=>{
          this.isRefreshing = false;
          this.store.dispatch(new AuthActions.UpdateAccessToken(token.accessToken)); //=> null
          this.store.dispatch(new AuthActions.UpdateRefreshToken(token.refreshToken));
          // add to localstorage
          localStorage.setItem('_access_token', JSON.stringify(token.accessToken));
          localStorage.setItem('_refresh_token', JSON.stringify(token.refreshToken));
          return next.handle(this.addToken(req, token.accessToken));
        }),
        catchError((error)=>{
          return throwError(error);
        })
      )
    }else{
      return this.accessToken$.pipe(
        filter(token => token !== 'Refreshing'),
        take(1),
        switchMap(token => {

          return next.handle(this.addToken(req, token));
        }),
        catchError((error) => {
          return throwError(error);
        })
      )
    }
  }

  private addToken(req: HttpRequest<any>, token: string){
    let newHeaders: HttpHeaders = req.headers.append('Content-Type', 'application/json')
      .set('Accept', '*/*');
    if(UploadRoute.UPLOADFILE === req.url){
      newHeaders = newHeaders.delete('Content-Type')
    }

    if (!this.untokenizedRoutes.includes(req.url)) {
      newHeaders = newHeaders.append('Authorization', 'Bearer ' + token)
    }

    const updatedRequest = req.clone({
      url: environment.api.url + 'api/' + req.url,
      headers: newHeaders
    });
    return updatedRequest;
  }

}
