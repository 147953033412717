import { featureCollections, geoJSON } from './../../shared/models/geoJSON.model';
import { SidebarMenuModel } from './../../shared/models/ui.model';
import { BaseMap } from './../../shared/models/base-map.model';
import { UIType } from "src/app/shared/models/ui.model";


export const UIActionTypes ={
  SetUI: '[UI] SetUI',
  Clear: '[UI] Clear',
  GetHomeBookmark: '[UI] GetHomeBookmark',
  GetMapSource: '[UI] GetMapSource',
  SetSidebarMenu: '[UI] SetSidebarMenu',
  SetActiveTrip: '[UI] SetActiveTrip',
  GetTrip: '[UI] GetTrip',
  GetTripDetails: '[UI] GetTripDetails',
  UpdateTripDetails: '[UI] UpdateTripDetails',
} as const;



export namespace UIActions{
  export class SetUI{
    static readonly type = UIActionTypes.SetUI
    constructor(public type: UIType, public value: boolean) {
    }
  }

  export class SetSidebarMenu{
    static readonly type = UIActionTypes.SetSidebarMenu
    constructor(public payload: SidebarMenuModel){}
  }

  export class Clear {
    static readonly type = UIActionTypes.Clear
  }

  export class GetHomeBookmark {
    static readonly type = UIActionTypes.GetHomeBookmark
  }

  export class GetMapSource{
    static readonly type = UIActionTypes.GetMapSource
    constructor(public payload: BaseMap | null){}
  }

  export class SetActiveTrip{
    static readonly type = UIActionTypes.SetActiveTrip
    constructor(public payload: {geoJSON: geoJSON | null, featureCollections: featureCollections} | null){}
  }

  export class GetTrip{
    static readonly type = UIActionTypes.GetTrip
    constructor(public payload: string){}
  }

  export class GetTripDetails{
    static readonly type = UIActionTypes.GetTripDetails
    constructor(public payload: string){}
  }

  export class UpdateTripDetails{
    static readonly type = UIActionTypes.UpdateTripDetails
    constructor(public payload: featureCollections){}
  }
}
