import { IconListModel } from './../models/icon-list.model';
import { BaseMap } from './../models/base-map.model';
import { SnackbarColorType } from './../constants/snackbar';
import { UserState } from './../../store/state/user.state';
import { MembershipTypes } from './../constants/memberships';
import { Bookmark } from './../models/bookmark.model';
import { SharedService } from './shared.service';
import { User } from './../models/user.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { exhaustMap, map, mergeMap, take, catchError } from 'rxjs/operators';
import { iif, Observable, throwError } from 'rxjs';
import { FileNode } from '../models/file-node.models';
import { APIRoutes } from '../constants/api_routes';
import { Select } from '@ngxs/store';
import { featureCollections } from '../models/geoJSON.model';
import { environment } from 'src/environments/environment';



@Injectable({
  providedIn: 'root'
})
export class HttpMapService {

  @Select(UserState.user) user$!: Observable<User>

  constructor(private http: HttpClient, private sharedService: SharedService){}

  downloadTrip({Authorization,fileName, type, username}: {Authorization: string, fileName: string, type: string, username: string}){
    window.open(`${environment.api.url}api/mobile/kmlfile?Authorization=${Authorization}&userName=${username}&fileName=${fileName}&type=${type}`);
  }

  updateTrip(trip: featureCollections){
    return this.http.post(APIRoutes.updateTrip,trip);
  }

  deleteTrip(tripId: string){
    return this.http.delete(APIRoutes.deleteTrip+tripId);
  }

  getTrip(tripId: string){
    return this.http.get(APIRoutes.getTrip + tripId);
  }

  upsertTrip(data: FormData){
    return this.http.post(APIRoutes.uploadTrip, data);
  }

  convertToGeoJson(data: any){
    return this.http.post(APIRoutes.convertToGeoJson,data);
  }

  getSavedMaps(){
    return this.http.get(APIRoutes.getSavedMap).pipe(
      map((treks: any)=> {
        const newArray = this.constructTreeMap(treks.mapDrawInfo);
        return newArray;
      })
    )
  }

  getIconList(){
    return this.http.get(APIRoutes.getIconList).pipe(
      map((iconList: any)=>{
       return iconList.payload[0].icons;
      })
    )
  }

  constructTreeMap(treks: any[]){
    let newArray: FileNode[] = [];
    let arrayIndex: number;
    treks.forEach((trek)=>{
      arrayIndex = newArray.findIndex((folder)=>folder.name === trek.trekFolder);
      trek.name = trek.trekName;
      if(arrayIndex !== -1){
        newArray[arrayIndex].children?.push(trek);
      }else{
        newArray.push({
          name: trek.trekFolder,
          children: [trek]
        })
      }
    })
    return newArray;
  }


  getHomeBookMark(){
    return this.http.get<{bookmarkInfo: Bookmark | null}>(APIRoutes.getHomeBookmark).pipe(
      map((bookMark: any)=>{
        if (bookMark.bookmarkInfo != null){
          return bookMark.bookmarkInfo;
        }
        return null;
      })
    )
  }

  getBookMarks(){
    return this.http.get(APIRoutes.getBookMarkedMap).pipe(
      map((bookMarks: any)=>{
        return bookMarks.bookmarkInfo;
      })
    )
  }

  saveBookMark(data: {bookmarkInfo: Bookmark}){
    return this.http.post(APIRoutes.saveBookMarkInfo, data).pipe(
      map((bookmark: any)=>{
        if (bookmark.status === 'Bookmark Updated successfully!'){
          const message = `Successfully updated bookmark: ${bookmark.bookmarkData.bookmarkName}.`;
          this.sharedService.showSnackBar(message, 'Dismiss', 5000, SnackbarColorType.SUCCESS);
          return {status: 200}
        }
        if (bookmark.status === 'Bookmark added successfully!'){
          const message = `Successfully added bookmark: ${bookmark.bookmarkData.bookmarkName}.`;
          this.sharedService.showSnackBar(message, 'Dismiss', 5000, SnackbarColorType.SUCCESS);
          return { status: 201 }
        }
        return bookmark.status;
      }),

    )
  }

  deleteBookMark(data: { data: (string | undefined)[]},bookmark: Bookmark){
    return this.http.post(APIRoutes.deleteBookmark,data).pipe(
      map((response: any)=>{
        if (response.status === "Bookmark deleted successfully"){
          const message = `Bookmark: ${bookmark.bookmarkName} has been successfully deleted.`;
          this.sharedService.showSnackBar(message, 'Dismiss', 5000, SnackbarColorType.SUCCESS);
          return { status: 200 }
        }
        return response.status;
      })
    )
  }

  getAddOns(){
    return this.http.get(APIRoutes.getAddOns).pipe(
      map((addOns: any)=>{
        return addOns.payload
      }),
      catchError((error) => {
      return throwError(error);
      })
    )
  }

  getMapSources(){
    return this.http.get(APIRoutes.getMapSources).pipe(
      map((mapSources: any)=>{
        return mapSources.mapSources
      })
    )
  }

  getMapSource(data: {shortname: string}){
    return this.http.post(APIRoutes.getMapSource, data.shortname).pipe(
      map((shortname: any)=>{
        return shortname.shortname;
      })
    )
  }

  addToFavorite(data: {shortname: string[]},name: string, isAdded: boolean){
    return this.http.post(APIRoutes.addToFavorite, data).pipe(
      map((response: any)=>{
        const message = (name + ' has been ' +(isAdded? 'added ':'removed ') +'to your favorites.');
        this.sharedService.showSnackBar(message, 'Dismiss', 5000, SnackbarColorType.SUCCESS);
        return response.userInfo;
      })
    )
  }


  //
  private filterMapMembership(mapSources: any[], membership: string){
    return mapSources.filter((m)=>{
      switch(membership){
        case MembershipTypes.Gold:
          return m;
          break;
        case 'Silver':
          if (m.membershipType === MembershipTypes.Silver || m.membershipType === MembershipTypes.Bronze || m.membershipType === MembershipTypes.Free){
            return m;
          }
          break;
        case 'Bronze':
          if (m.membershipType === MembershipTypes.Bronze || m.membershipType === MembershipTypes.Free) {
            return m;
          }
          break
        default:
          if (m.membershipType === MembershipTypes.Bronze || m.membershipType === MembershipTypes.Free) {
            return m;
          }
        break;
      }
    })
  }
}
