import { Coordinates, addressInterface } from './../../shared/constants/map';
import { SearchResultsInterface } from './../../shared/constants/search_results';
import { Injectable } from '@angular/core';
import GraphicsLayer from '@arcgis/core/layers/GraphicsLayer';
import Map from '@arcgis/core/Map';
import MapView from '@arcgis/core/views/MapView';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { MapStateModel } from './../../shared/models/map.model';
import Search from '@arcgis/core/widgets/Search';
import { MapActions } from '../actions/map.actions';
import Collection from '@arcgis/core/core/Collection';
import Graphic from '@arcgis/core/Graphic';
import { IGraphicData } from 'src/app/dashboard/map/side-nav/drawing-contents/drawing-content.interface';


const DEFAULT_MAP_STATE: MapStateModel = {
  mapView: null,
  map: null,
  mainGraphics: [],
  drawGraphics: null,
  search: null,
  searchResult: null,
  center: null,
  address: null
}

@State<MapStateModel>({
  name: "map",
  defaults: DEFAULT_MAP_STATE
})

@Injectable()
export class MapState{

  @Selector()
  static mapView(state: MapStateModel): MapView | null{
    return state.mapView
  }

  @Selector()
  static map(state: MapStateModel): Map | null {
    return state.map
  }

  @Selector()
  static mainGraphics(state: MapStateModel): IGraphicData[] {
    return state.mainGraphics
  }

  @Selector()
  static drawGraphics(state: MapStateModel): GraphicsLayer | null {
    return state.drawGraphics
  }

  @Selector()
  static search(state: MapStateModel): Search | null {
    return state.search
  }

  @Selector()
  static searchResult(state: MapStateModel): SearchResultsInterface | null {
    return state.searchResult;
  }

  @Selector()
  static address(state: MapStateModel): addressInterface | null {
    return state.address;
  }

  @Action(MapActions.GetMap)
  getMap(ctx: StateContext<MapStateModel>, { payload }: MapActions.GetMap){
    const state = ctx.getState();
    ctx.setState({
      ...state,
      map: payload
    })
  }

  @Action(MapActions.GetMapView)
  getMapView(ctx: StateContext<MapStateModel>, { payload }: MapActions.GetMapView) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      mapView: payload
    })
  }

  @Action(MapActions.GetDrawGraphics)
  getDrawGraphics(ctx: StateContext<MapStateModel>, { payload }: MapActions.GetDrawGraphics) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      drawGraphics: payload
    })
  }


  @Action(MapActions.UpdateMainGraphics)
  updateMainGraphics(ctx: StateContext<MapStateModel>, { payload }: MapActions.UpdateMainGraphics) {
    const state = ctx.getState();
    const graphicIndex = ctx.getState().mainGraphics.findIndex(graphic => graphic.uid ===  payload.uid);
    const newMainGraphicsData = ctx.getState().mainGraphics.filter(graphic=> graphic.uid !== payload.uid);
    newMainGraphicsData.splice(graphicIndex,0,payload);
    ctx.patchState({
      mainGraphics: [...newMainGraphicsData]
    })
  }

  @Action(MapActions.ClearMainGraphics)
  clearMainGraphics(ctx: StateContext<MapStateModel>){
    const state = ctx.getState();
    ctx.patchState({
      mainGraphics: []
    })
  }

  @Action(MapActions.DeleteMainGraphic)
  deleteMainGraphic(ctx: StateContext<MapStateModel>, { payload }: MapActions.DeleteMainGraphic) {
    const state = ctx.getState();
    ctx.patchState({
      mainGraphics: ctx.getState().mainGraphics.filter(graphic=> graphic.uid !== payload)
    })
  }

  @Action(MapActions.SetSearchResult)
  getSearchResult(ctx: StateContext<MapStateModel>, {payload}: MapActions.SetSearchResult){
    const state = ctx.getState();
    ctx.setState({
      ...state,
      searchResult: payload
    })
  }

  @Action(MapActions.Clear)
  clear(ctx: StateContext<MapStateModel>) {
    const state = ctx.getState();
    return ctx.setState({
      ...state,
      ...DEFAULT_MAP_STATE,
    });
  }

  @Action(MapActions.SetCenter)
  setCenter(ctx: StateContext<MapStateModel>, {payload}: MapActions.SetCenter){
    const state = ctx.getState();
    ctx.setState({
      ...state,
      center: payload
    })
  }

  @Action(MapActions.SetAddress)
  setAddress(ctx: StateContext<MapStateModel>, {payload}: MapActions.SetAddress){
    const state = ctx.getState();
    ctx.setState({
      ...state,
      address: payload
    })
  }
}
