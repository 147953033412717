<mat-dialog-content>
  <div class="container">
    <div class="icon-container">
      <mat-icon [style.color]="data.matIcon.color">{{data.matIcon.name}}</mat-icon>
    </div>
    <div class="message-container">
      <h3>{{data.header}}</h3>
      <!-- <p>{{data.message}}</p> -->
      <p [innerHTML]="data.message"></p>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>{{data.options.no}}</button>
  <button mat-button (click)="close('yes')">{{data.options.yes}}</button>
</mat-dialog-actions>
