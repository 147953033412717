import Graphic from "@arcgis/core/Graphic";
import { IGraphicData } from "../../../side-nav/drawing-contents/drawing-content.interface";
import { CoordinatesObj } from "src/app/shared/constants/map";
import * as webMercatorUtils from "@arcgis/core/geometry/support/webMercatorUtils";

// Return a geometry
export class GraphicFactory{
  public static initializeGraphic(graphic: Graphic): IGraphicData | null{


    switch(graphic.geometry.type){
      case 'point':
      return new Point(graphic, new GraphicInitializerClass());
      case 'polyline':
      return new Polyline(graphic, new GraphicInitializerClass());
      case 'polygon':
      return new Polygon(graphic, new GraphicInitializerClass());
      default:
        return null;
    }
  }

}

interface IGraphicInitializer{
  initializeData: <T extends string | number>(graphic: Graphic, propertyName: string) => T
}

class GraphicInitializerClass implements IGraphicInitializer{
  initializeData<T>(graphic: Graphic, propertyName: string): T{
    return graphic.get<T>(propertyName);
  }
}

abstract class BaseDefault {
  desc!: string;
  uid!: number;
  text!: string;
  abstract icon:string;
  abstract center: CoordinatesObj;
  abstract path: [number,number][];
  abstract type: 'polyline' | 'polygon' | 'point';

  abstract initializeShape(graphic: Graphic): void;
  constructor(graphic: Graphic, graphicInitalizer: IGraphicInitializer){
    this.setDefaults(graphic, graphicInitalizer);
  }

  setDefaults(graphic: Graphic, graphicInitializer: IGraphicInitializer){
    this.desc = graphicInitializer.initializeData<string>(graphic, 'desc');
    this.uid = graphicInitializer.initializeData<number>(graphic, 'uid');
    this.text = graphicInitializer.initializeData<string>(graphic, 'text');
  }
}


class Point extends BaseDefault implements IGraphicData{
  icon!:string;
  center!: CoordinatesObj;
  path!: [number,number][];
  type!: 'polyline' | 'polygon' | 'point';

  constructor(graphic: Graphic, graphicInitializer: IGraphicInitializer){
    super(graphic, graphicInitializer);
    this.initializeShape(graphic);
  }


  initializeShape(graphic: Graphic){
    this.icon = graphic.symbol.get<string>('url');
    this.center = {
    lat: graphic.geometry.get('latitude'),
    long: graphic.geometry.get('longitude'),
    zoom: 16
    };
    this.path = [[graphic.geometry.get('latitude'), graphic.geometry.get('longitude')]];
    this.type = graphic.geometry.type as 'point';
  }

}

class Polyline extends BaseDefault implements IGraphicData{
  center!: CoordinatesObj;
  desc!: string;
  uid!: number;
  icon!:string;
  path!: [number,number][];
  text!: string;
  type!: 'polyline' | 'polygon' | 'point';

  constructor(graphic: Graphic, graphicInitializer: IGraphicInitializer){
    super(graphic, graphicInitializer);
    this.initializeShape(graphic);
  }

  initializeShape(graphic: Graphic){
    this.icon = './assets/images/icons/polyline.png';
    this.center = {
    lat: graphic.geometry.get<any>('extent').center.latitude,
    long: graphic.geometry.get<any>('extent').center.longitude,
    zoom: 16
    };
    this.path = graphic.geometry.get<[[number,number][]]>('paths')[0].map((coordinates: [number,number])=>convertXYtoLngLat(coordinates[0],coordinates[1]))
    this.type = graphic.geometry.type as 'polyline';
  }
}

class Polygon extends BaseDefault implements IGraphicData{
  center!: CoordinatesObj;
  desc!: string;
  uid!: number;
  icon!:string;
  path!: [number,number][];
  text!: string;
  type!: 'polyline' | 'polygon' | 'point';

  constructor(graphic: Graphic, graphicInitializer: IGraphicInitializer){
    super(graphic, graphicInitializer);
    this.initializeShape(graphic);
  }

  initializeShape(graphic: Graphic){
    this.icon = './assets/images/icons/polygon.png';
    this.center = {
    lat: graphic.geometry.get<any>('centroid').latitude,
    long: graphic.geometry.get<any>('centroid').longitude,
    zoom: 16
    };
    this.path = graphic.geometry.get<[[number,number][]]>('rings')[0].map((coordinates: [number,number])=>convertXYtoLngLat(coordinates[0],coordinates[1]))
    this.type = graphic.geometry.type as 'polygon';
  }

}


const convertXYtoLngLat = (x: number, y: number): [number,number] =>{
  return webMercatorUtils.xyToLngLat(x,y) as [number,number]
}
