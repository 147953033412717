export const AddOnsActionTypes = {
  GetAddOns: '[ADDONS] GetAddOns',
  Clear: '[ADDONS] Clear'
} as const;


export namespace AddOnsActions{

  export class GetAddOns {
    static readonly type = AddOnsActionTypes.GetAddOns;
  }


  export class Clear {
    static readonly type = AddOnsActionTypes.Clear;
  }

}
