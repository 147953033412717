import { environment } from './../../../environments/environment.staging';
import { GeometryTypes } from './../constants/geometry_types';
import { Injectable } from "@angular/core";
import * as webMercatorUtils from "@arcgis/core/geometry/support/webMercatorUtils";
import { geoJSON } from '../models/geoJSON.model';
import { DrawConstants } from '../constants/draw.constants';
import { User } from '../models/user.model';
import { Defaults } from '../constants/default.constants';


@Injectable({providedIn: 'root'})
export class UtilitiesService{



  convertNumberToRGBA(num: number){
    let b = num & 0xFF,
    g = (num & 0xFF00) >>> 8,
    r = (num & 0xFF0000) >>> 16,
    a = 255 ;
    return  [r, g, b, a];
  }

  log(value: any){
    if(!environment.isProduction){
    }
  }

  validateUser(user: User): User{
    if(user.measurement){
      return user;
    }else{
      user.measurement = Defaults.measurement;
      return user;
    }
  }

  iconSetter(imgUrl: string){
    if(imgUrl === DrawConstants.defaultPointSymbol){
      return imgUrl;
    }else{
      return '/assets/images/icons/svg/s_' + imgUrl + '.svg';
    }
  }

  formatTogeoJSON(data: any){
    const geoJSON: geoJSON = {
    features: [],
    type: 'FeatureCollection',
    originalName: data.trip[0].id
    }
    if(data.geometry){
      let coordinates;
      data.geometry.forEach((geometry: any, index: number)=>{
        if(geometry.geoJSON.paths && geometry.geoJSON.paths[0]){
          coordinates = geometry.geoJSON.paths[0];
        }else
        if(geometry.geoJSON.rings && geometry.geoJSON.rings[0]){
          coordinates = geometry.geoJSON.rings[0];
        }
        else{
          coordinates = [geometry.geoJSON.x, geometry.geoJSON.y]
        }
        // color
        let color = geometry.color;
        if(this.isNumber(color)){
          // number to hex
          let result = this.numberToColourARGB(color);
          color =  "#" + this.componentToHex(result.r) + this.componentToHex(result.g) + this.componentToHex(result.b);
        }else if(color && color.length >= 0){
          // rgb to color (none)
          color = JSON.parse(color)
        }else if(color.includes('#')){
          // hex (none)

        }

        geoJSON.features.push({
          geometry: {
            type:  geometry.type,
            coordinates: coordinates,
            geometryJson: geometry.geoJSON
          },
          properties: {
            desc: geometry.desc,
            imageUrl: geometry.imageUrl,
            sym: geometry.symbol,
            name: geometry.name,
            color: color
          },
          type: 'Feature'
        })
      })
    }
    return geoJSON;
  }


  convertlngLatToXY([x,y,z]: [number,number, (number | null)?]){
    return webMercatorUtils.lngLatToXY(x,y);
  }

  convertXYtoLngLat(x: number, y: number): number[]{
    return webMercatorUtils.xyToLngLat(x,y)
  }

  isNumber(n: any) { return /^-?[\d.]+(?:e-?\d+)?$/.test(n); }

  formatGeometry(geometry: string ): GeometryTypes | null{
    switch(geometry){
      case 'Point':
      case 'point':
        return 'point';
      case 'LineString':
      case 'linestring':
      case 'MultiLineString':
      case 'polyline':
      case 'Polyline':
        return 'polyline';
      case 'polygon':
      case 'Polygon':
        return 'polygon';
      default:
          return null;
    }
  }

   hexToRgb(hex: string) {
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, function(m, r, g, b) {
        return r + r + g + g + b + b;
    });

    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
    } : null;
  }

   ARGBcolourToNumber(a: number, r: number, g: number, b: number) {
    return Number((a<<24) + (r << 16) + (g << 8) + (b));
  }

  capitalize (s: any) {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
  }


  uuid() {
    const s4 = () =>{
      return Math.floor((1 + Math.random()) * 0x10000)
          .toString(16)
          .substring(1);
    }
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
        s4() + '-' + s4() + s4() + s4();
  }


    numberToColourARGB(number: number): {a: number, r: number, g: number, b: number} {
    let result = {
      a: 0,
      r: 0,
      g: 0,
      b:0
    };
    result.a = (number & 0xff000000) >> 24;
    result.r = (number & 0xff0000) >> 16;
    result.g = (number & 0x00ff00) >> 8;
    result.b = (number & 0x0000ff);
    return result;
    }

    componentToHex(c: number) {
    let hex = c.toString(16);
    return hex.length == 1 ? "0" + hex : hex;
    }





}
