import { addressInterface } from './../constants/map';
import { MapTypeInterface } from './../models/map-type.model';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterMap'
})
export class FilterMapPipe implements PipeTransform {

  transform(value: any[], filterString: string,
    topo: boolean,
    marine: boolean,
    hybrid: boolean,
    satellite: boolean,
    street: boolean,
    aviation: boolean,
    wwww: boolean,
    address: addressInterface | null
    ): any[] {
    if(!value){
      return value;
    }

    const resultArray: any[] = [];
    // text filter
    for (const item of value){
      if (item['shortname'].toLowerCase().includes(filterString.toLowerCase()) || item['membershipType'].toLowerCase().includes(filterString.toLowerCase()) || item['name'].toLowerCase().includes(filterString.toLowerCase())){
        resultArray.push(item);
      }
    }


  // layer visibility filter
  const filterVisibilityArray = resultArray.filter((element)=>{
    return element.visibility !== 'layer'
  })

  //location filter
  const filterLocationArray = filterVisibilityArray.filter((element)=>{
      if(element.location.ww === 'ww'){
        return element;
      }else if((element.location.country.includes(address?.country.toUpperCase()) || element.location.country.includes(address?.country.toLowerCase())) && address?.country !== ''){
        return element;
      }else if((element.location.state.includes(address?.state.toUpperCase()) || element.location.state.includes(address?.state.toLowerCase()))  && address?.state !== ''){
        return element;
      }
  })

   return filterLocationArray.filter((element)=>{
      if(topo){
        if (element.mapsubject.includes('topo')){
          return element
        }
      }
      if (marine) {
        if (element.mapsubject.includes('marine')) {
          return element
        }
      }
      if (hybrid) {
        if (element.mapsubject.includes('hybrid')) {
          return element
        }
      }
      if (satellite) {
        if (element.mapsubject.includes('satellite')) {
          return element
        }
      }
      if (street) {
        if (element.mapsubject.includes('street')) {
          return element
        }
      }
      if (aviation) {
        if (element.mapsubject.includes('aviation')) {
          return element
        }
      }
      if (wwww) {
        if (element.mapsubject.includes('wwww')) {
          return element
        }
      }
    })

  }

}
