import { USERMANUAL_IOS } from './user-manual/constants/user-manual-ios.constants';
import { USERMANUAL_ANDROID } from './user-manual/constants/user-manual-android.constants';
import { USERMANUAL_WEB } from './user-manual/constants/user-manual-web.constant';
import { OAuthAcomponent } from './shared/components/oauth/oauth.component';
import { PurchaseComponent } from './shared/components/purchase/purchase.component';
import { NotFoundComponent } from './shared/components/not-found/not-found.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';


const routes: Routes = [
  {path: '', loadChildren: () => import('./landing/landing.module').then(m=> m.LandingModule)},
  {path: 'dashboard', loadChildren: ()=> import('./dashboard/dashboard.module').then(m=>m.DashboardModule)},
  {path: 'web/user-manual/:page', loadChildren: ()=> import('./user-manual/user-manual.module').then(m=>m.UserManualModule), data: {manual: JSON.parse(JSON.stringify(USERMANUAL_WEB))}},
  {path: 'android/user-manual/:page', loadChildren: ()=> import('./user-manual/user-manual.module').then(m=>m.UserManualModule), data: {manual: JSON.parse(JSON.stringify(USERMANUAL_ANDROID))}},
  {path: 'ios/user-manual/:page', loadChildren: ()=> import('./user-manual/user-manual.module').then(m=>m.UserManualModule), data: {manual: JSON.parse(JSON.stringify(USERMANUAL_IOS))}},
  {path: 'admin', loadChildren: ()=> import('./admin/admin.module').then(m=>m.AdminModule)},
  { path: 'purchase', component: PurchaseComponent },
  { path: 'oauth', component: OAuthAcomponent},
  { path: '**', redirectTo: '404-not-found', pathMatch: 'full' },
  {path: '404-not-found', component: NotFoundComponent}

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', anchorScrolling: 'enabled', onSameUrlNavigation: 'reload',
  scrollOffset: [0, 94], useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
