<ng-container *ngIf="checkhasHeader() | async">
<app-header></app-header>
</ng-container>


  <ng-container *ngIf="!isLoading; else loading">
    <router-outlet></router-outlet>
  </ng-container>


  <ng-template #loading>
    <div style="width: 100%; height: 100%; background: #F8F8F8">
      <span class="spinner spinner-large"></span>
    </div>
  </ng-template>



  <ng-container *ngIf="checkhasHeader() | async">
    <app-footer></app-footer>
  </ng-container>

<app-downloads *ngIf="false"></app-downloads>
