export class DrawConstants{
  // public static readonly defaultPointSymbol = "https://maps.google.com/mapfiles/kml/shapes/placemark_circle.png";
  public static readonly defaultPointSymbol = '../../../../../../assets/images/icons/svg/s_trianglered.svg';
}

export const DrawingState = {
  UPDATE: 'Update',
  COMPLETE: 'Complete',
  MULTI: 'Multi'
} as const;

export const DrawTypes = {
  POINT: 'point',
  POLYLINE: 'polyline',
  MULTIPOINT: 'multipoint'
} as const;

export type DrawingStatus = 'Update'| 'Complete' | 'Multi'
