import { Bookmark } from './../../shared/models/bookmark.model';

export const BookMarksActionTypes = {
  GetBookmarks: '[BOOKMARK] GetBookmarks',
  DeleteBookmark: '[BOOKMARK] DeleteBookmark',
  UpsertBookmark: '[BOOKMARK] UpsertBookmark',
  Clear: '[BASEMAPS] Clear',
} as const;


export namespace BookmarkActions{

  export class GetBookmarks {
    static readonly type = BookMarksActionTypes.GetBookmarks;
  }


  export class DeleteBookmark {
    static readonly type = BookMarksActionTypes.DeleteBookmark;
    constructor(public payload: string) { }
  }

  export class UpsertBookmark {
    static readonly type = BookMarksActionTypes.UpsertBookmark;
    constructor(public payload: Bookmark) { }
  }

  export class Clear {
    static readonly type = BookMarksActionTypes.Clear;
  }
}
