// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  isProduction: false,
  test:{
    url: 'http://localhost:4200/',
  },
  wasm:{
    url: 'https://staging.bcnavxe.com/doc/sql-wasm.wasm'
  },
  api:{
    url: 'https://staging.bcnavxe.com/',
    clientURL: 'https://staging.bcnavxe.com/',
  },
  arcgis: {
    apiKey:'AAPK6932c9a21d144df1a3284d79d31be925boNdZ_jy5CKggSRrWdcRHQ7ssUJb9xZ7LJtwu2G4wV5nuRDzxAZcrxGHISeMdqH6'
  },
  coin: {
    selected: "5ae20712b9122f589ea6845a"
  },
  stripe:{
    publishableKey: "pk_test_i2ZDlsDRfsEY6sdtBoYaW6Jo",
    publicKey: "pk_test_I5DfULkLWAmTujia9oPWdezD",
    secretKey: "sk_test_jmSvZJW8aXFAVwrUHadcJWXt",
    coins: {
      large: 'prod_KUE4uOzbmgsVbv',
      medium: 'prod_KUE3ThTBHiCg5i',
      small: 'prod_KUE2GodWeq27Dw'
    }
  },
  printer:{
    serviceUrl: "https://utility.arcgisonline.com/arcgis/rest/services/Utilities/PrintingTools/GPServer/Export%20Web%20Map%20Task"
  },
  openRouteService:{
    apiKey: '5b3ce3597851110001cf6248163ee1603abc499ab549acd13c0c87ef'
  },
  googleMaps:{
    apiKey: 'AIzaSyCW93dOAou8mdqWOiI7HJbjJ9AkRqFSXng'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
