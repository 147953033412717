import { ActiveTrip, featureCollections, geoJSON } from './../../shared/models/geoJSON.model';
import { BaseMap } from './../../shared/models/base-map.model';
import { tap } from 'rxjs/operators';
import { HttpMapService } from './../../shared/services/http-map.service';
import { Bookmark } from './../../shared/models/bookmark.model';
import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { UIActions } from '../actions/ui.actions';
import { SidebarMenuModel, UI, UIModel } from './../../shared/models/ui.model';
import { UserState } from './user.state';


const DEFAULT_UI_STATE: UIModel = {
  isTripLoading: false,
  isBaseMapLoading: false,
  isBookmarkLoading: false,
  isLogoutLoading: false,
  isDrawingMode: false,
  isUploadingFile: false,
  isSplitTrackMode: false,

  homeBookmark: null,
  mapSource: null,

  isChangesSaved: true,
  isSideBarVisible: false,
  isSideBarOpen: false,
  canAccessUploadTrek: false,
  canAccessBookmarks: false,
  canAccessHome: false,
  canAccessPrint: false,
  canAccessDraw: false,
  canAccessBasemaps: true,

  tripHasLoaded: false,

  isGeometryLoading: false,
  sidebarMenus: {
    isMyTripOpen: true,
    isDrawOptionsOpen: false,
    isTripOptionsOpen: false,
  },
  isTripSaving: false,
  activeTrip: null,

  isCardOpen: false,

}

@State<UIModel>({
  name: 'ui',
  defaults: DEFAULT_UI_STATE,
})
@Injectable()
export class UIState {
  constructor(private httpMapService: HttpMapService,
    private store: Store) {}
  @Selector()
  static getActiveTrip(state: UIModel):  ActiveTrip | null{
    return state.activeTrip;
  }

  @Selector()
  static isGeometryLoading(state: UIModel): boolean {
    return state.isGeometryLoading;
  }

  @Selector()
  static isSplitTrackMode(state: UIModel): boolean {
    return state.isSplitTrackMode;
  }
  @Selector()
  static isTripLoading(state: UIModel): boolean {
    return state.isTripLoading;
  }

  @Selector()
  static isBaseMapLoading(state: UIModel): boolean {
    return state.isBaseMapLoading;
  }

  @Selector()
  static isBookmarkLoading(state: UIModel): boolean {
    return state.isBookmarkLoading;
  }

  @Selector()
  static isLogoutLoading(state: UIModel): boolean {
    return state.isLogoutLoading;
  }

  @Selector()
  static isDrawingMode(state: UIModel): boolean {
    return state.isDrawingMode;
  }

  @Selector()
  static isSideBarVisible(state: UIModel): boolean {
    return state.isSideBarVisible;
  }

  @Selector()
  static isSideBarOpen(state: UIModel): boolean {
    return state.isSideBarOpen;
  }

  @Selector()
  static canAccessUploadTrek(state: UIModel): boolean {
    return state.canAccessUploadTrek;
  }

  @Selector()
  static canAccessHome(state: UIModel): boolean {
    return state.canAccessHome;
  }

  @Selector()
  static canAccessBookmarks(state: UIModel): boolean {
    return state.canAccessBookmarks;
  }

  @Selector()
  static canAccessPrint(state: UIModel): boolean {
    return state.canAccessPrint;
  }

  @Selector()
  static canAccessDraw(state: UIModel): boolean {
    return state.canAccessDraw;
  }

  @Selector()
  static canAccessBasemaps(state: UIModel): boolean {
    return state.canAccessBasemaps;
  }

  @Selector()
  static homeBookmark(state: UIModel): Bookmark | null {
    return state.homeBookmark;
  }

  @Selector()
  static mapSource(state: UIModel): BaseMap | null {
    return state.mapSource;
  }

  @Selector()
  static tripHasLoaded(state: UIModel): boolean {
    return state.tripHasLoaded;
  }

  @Selector()
  static sidebarMenus(state: UIModel): SidebarMenuModel {
    return state.sidebarMenus;
  }

  @Selector()
  static isTripSaving(state: UIModel): boolean {
    return state.isTripSaving;
  }

  @Selector()
  static isUploadingFile(state: UIModel): boolean {
    return state.isUploadingFile;
  }

  @Selector()
  static isCardOpen(state: UIModel): boolean {
    return state.isCardOpen;
  }

  @Action(UIActions.SetSidebarMenu)
  setSidebarMenu(
    ctx: StateContext<UIModel>,
    { payload }: UIActions.SetSidebarMenu
  ) {
    const state = ctx.getState();
    return ctx.setState({
      ...state,
      sidebarMenus: payload,
    });
  }

  @Action(UIActions.SetUI)
  setUI(ctx: StateContext<UIModel>, { ...payload }: UIActions.SetUI) {
    const state = ctx.getState();
    return ctx.setState({
      ...state,
      [payload.type]: payload.value,
    });
  }

  @Action(UIActions.Clear)
  clear(ctx: StateContext<UIModel>) {
    const state = ctx.getState();
    return ctx.setState({
      ...state,
      ...DEFAULT_UI_STATE,
    });
  }

  @Action(UIActions.GetHomeBookmark)
  getHomeBookmark(ctx: StateContext<UIModel>) {
    return this.httpMapService.getHomeBookMark().pipe(
      tap((bookmark) => {
        const state = ctx.getState();
        return ctx.setState({
          ...state,
          homeBookmark: bookmark,
        });
      })
    );
  }

  @Action(UIActions.GetMapSource)
  getMapSource(
    ctx: StateContext<UIModel>,
    { payload }: UIActions.GetMapSource
  ) {
    const state = ctx.getState();
    return ctx.setState({
      ...state,
      mapSource: payload,
    });
  }

  @Action(UIActions.SetActiveTrip)
  setActiveTrip(ctx: StateContext<UIModel>, { payload } : UIActions.SetActiveTrip){
    const state = ctx.getState();
    return ctx.setState({
      ...state,
      activeTrip: payload
    })
  }

  @Action(UIActions.GetTripDetails)
  getTripDetails(ctx: StateContext<UIModel>, { payload }: UIActions.GetTripDetails){
    return this.httpMapService.getTrip(payload).pipe(
      tap((response: any) => {
        if(payload.length != 0){
          const state = ctx.getState();
          ctx.setState({
            ...state,
            activeTrip: {
              geoJSON: state.activeTrip?.geoJSON || null,
              featureCollections: response.payload[0]
            }
          });
        }
      })
    );
  }

  @Action(UIActions.UpdateTripDetails)
  updateTripDetails(ctx: StateContext<UIModel>, {payload}: UIActions.UpdateTripDetails){
    return this.httpMapService.updateTrip(payload).pipe(
      tap((response: any)=>{
        if(response.responseCode === 1 && response.payload){
          const state = ctx.getState();
          ctx.setState({
            ...state,
            activeTrip: {
              geoJSON: state.activeTrip?.geoJSON || null,
              featureCollections: response.payload
            }
          })
        }
      })
    )
  }
}
