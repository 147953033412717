
import { LoginInterface, SignUpInterface } from './../../shared/models/auth.interface';

export const AuthActionTypes = {
  Login: '[AUTH] Login',
  AutoLogin: '[AUTH] Auto Login',
  UpdateRefreshToken: '[AUTH] UpdateRefreshToken',
  UpdateAccessToken: '[AUTH] UpdateAccessToken',
  Logout: '[AUTH] Logout',
  Signup: '[AUTH] Signup',
  Clear: '[AUTH] Clear',
} as const;


export namespace AuthActions{

  export class Login {
    static readonly type = AuthActionTypes.Login;
    constructor(public payload: LoginInterface) { }
  }

  export class UpdateRefreshToken {
    static readonly type = AuthActionTypes.UpdateRefreshToken;
    constructor(public payload: string | null) { }
  }

  export class UpdateAccessToken {
    static readonly type = AuthActionTypes.UpdateAccessToken;
    constructor(public payload: string | null) { }
  }

  export class Logout {
    static readonly type = AuthActionTypes.Logout;
  }

  export class Signup {
    static readonly type = AuthActionTypes.Signup;
    constructor(public payload: SignUpInterface) { }
  }

  export class Clear{
    static readonly type = AuthActionTypes.Clear;
  }

  export class AutoLogin {
    static readonly type = AuthActionTypes.AutoLogin;
  }



}
