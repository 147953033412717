import { MapHeaderService } from './../../dashboard/map/map-header/map-header.service';
import { MapFactoryService } from './../../dashboard/map/map-factory.service';
import { UserDataState } from './user-data.state';
import { UserState } from './user.state';
import { UI } from './../../shared/models/ui.model';
import { BookmarkActions } from 'src/app/store/actions/bookmarks.actions';
import { BaseMapActions } from 'src/app/store/actions/base-map.actions';
import { SnackbarColorType } from './../../shared/constants/snackbar';
import { Router } from '@angular/router';
import { SharedService } from './../../shared/services/shared.service';
import { UserActions } from 'src/app/store/actions/user.actions';
import { Injectable } from '@angular/core';
import { tap, mapTo, catchError } from 'rxjs/operators';
import { HttpAuthService } from './../../shared/services/http-auth.service';
import { AuthActions } from './../actions/auth.actions';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { AuthInterface } from './../../shared/models/auth.interface';
import { of, throwError } from 'rxjs';
import { UIActions } from '../actions/ui.actions';
import { TripActions } from '../actions/trip.actions';
import { MapActions } from '../actions/map.actions';
import { UserDataActions } from '../actions/user-data.actions';
import { DrawActions } from '../actions/draw.actions';
import { AddOnsActions } from '../actions/add-ons.actions';

const DEFAULT_AUTH_STATE: AuthInterface = {
  accessToken: null,
  refreshToken: null,
}

@State<AuthInterface>({
  name: 'auth',
  defaults: DEFAULT_AUTH_STATE
})
  @Injectable()
export class AuthState{
  constructor(private httpAuthService: HttpAuthService,
    private sharedService: SharedService,
    private mapFactoryService: MapFactoryService,
    private mapHeaderService: MapHeaderService,
    private router: Router, private store: Store){}
  @Selector()
  static getAccessToken(state: AuthInterface): string{
    return <string>state.accessToken;
  }

  @Selector()
  static getRefreshToken(state: AuthInterface): string {
    return <string>state.refreshToken;
  }

  @Action(AuthActions.Signup)
  signup(ctx: StateContext<AuthInterface>, {payload}: AuthActions.Signup){
    return this.httpAuthService.signup(payload)
    .pipe(catchError((error) => {
      const errorMessage = 'An unknown error occured.'
      if (error.error.payload && error.status === 500) {
        this.sharedService.showSnackBar(error.error.payload, 'Dismiss', 5000, SnackbarColorType.WARNING);
      } else {
        this.sharedService.showSnackBar(errorMessage, 'Dismiss', 5000, SnackbarColorType.WARNING);
      }
      return throwError(null);
    }),
      tap(({ accessToken, refreshToken }) => {
        const state = ctx.getState();
        ctx.setState({
          ...state,
          accessToken,
          refreshToken,
        })
        this.handleAuthentication(ctx);
      })
    )
  }

  @Action(AuthActions.Login)
  login(ctx: StateContext<AuthInterface>, { payload }: AuthActions.Login){
    return this.httpAuthService.login(payload)
    .pipe(catchError((error) => {
      const errorMessage = 'An unknown error occured.'
      if(error.error.responseText === 'GMAILUSERNOTVERIFIED'){
        this.sharedService.showSnackBar(error.error.message, 'Dismiss', 5000, SnackbarColorType.WARNING);
      }else
      if (error.error.err.name === "IncorrectUsernameError") {
        this.sharedService.showSnackBar(error.error.err.message, 'Dismiss', 5000, SnackbarColorType.WARNING);
      } else
        if (error.error.err.name === "IncorrectPasswordError") {
          this.sharedService.showSnackBar('Password is incorrect.', 'Dismiss', 5000, SnackbarColorType.WARNING);
        }
        else {
          this.sharedService.showSnackBar(errorMessage, 'Dismiss', 5000, SnackbarColorType.WARNING);
        }
      return throwError(null);
    }),
    tap(({ accessToken, refreshToken }) => {
      const state = ctx.getState();
      ctx.setState({
        ...state,
        accessToken,
        refreshToken,
      })
      this.handleAuthentication(ctx);
    })
    )
  }


  @Action(AuthActions.UpdateAccessToken)
  updateAccessToken(ctx: StateContext<AuthInterface>, { payload }: AuthActions.UpdateAccessToken){
    const state = ctx.getState();
   return ctx.patchState({
      accessToken: payload
    })
  }

  @Action(AuthActions.UpdateRefreshToken)
  updateRefreshToken(ctx: StateContext<AuthInterface>, { payload }: AuthActions.UpdateRefreshToken) {
    const state = ctx.getState();
   return ctx.patchState({
      refreshToken: payload
    })
  }


  @Action(AuthActions.Logout)
  logout(ctx: StateContext<AuthInterface>){
    return this.httpAuthService.logout()
      .pipe(
        tap((response) => {
          this.handleLogoutClearer(ctx);
        }),
        mapTo(true),
        catchError((error) => {
          this.sharedService.showSnackBar('An unexpected error occured', 'Dismiss', 5000, SnackbarColorType.WARNING);
          ctx.dispatch(new UIActions.SetUI(UI.ISLOGOUTLOADING,false))
          return of(false);
        })
      )
  }

  @Action(AuthActions.Clear)
  clear(ctx: StateContext<AuthInterface>){
    const state = ctx.getState();
    return ctx.setState({
      ...state,
      ...DEFAULT_AUTH_STATE
    })
  }

  @Action(AuthActions.AutoLogin)
  autoLogin({dispatch}: StateContext<AuthInterface>){
    const tokenData = JSON.parse(<string>localStorage.getItem('_access_token'));
    const refreshToken = JSON.parse(<string>localStorage.getItem('_refresh_token'));
    if (refreshToken) {
      dispatch(new AuthActions.UpdateRefreshToken(refreshToken));
    }
    if (!tokenData) {
      return;
    } else
      if (tokenData) {
        dispatch([new AuthActions.UpdateAccessToken(tokenData), new UserActions.GetUser()]).subscribe(()=>{
          dispatch([new UserDataActions.GetMembership(this.store.selectSnapshot(UserState.membership))]).subscribe(()=>{
            this.sharedService.roleAccessInit();

          })
        });
      }
  }


  private handleLogoutClearer({dispatch}: StateContext<AuthInterface>){
     dispatch([
          new UserActions.Clear(),
          new UserDataActions.Clear(),
          new AuthActions.Clear(),
          new BaseMapActions.Clear(),
          new BookmarkActions.Clear(),
          new MapActions.Clear(),
          new UIActions.Clear(),
          new TripActions.Clear(),
          new DrawActions.Clear(),
          new AddOnsActions.Clear(),
          ]);


          this.mapFactoryService.resetGraphics();
          this.mapHeaderService.cardType.next(null);
          localStorage.clear();
          this.router.navigate(['']);
          this.sharedService.showSnackBar('Logged out successfully!', 'Dismiss', 5000, SnackbarColorType.SUCCESS);
  }

  private handleAuthentication({dispatch, getState}: StateContext<AuthInterface>) {
    const state = getState();
    localStorage.setItem('_access_token', JSON.stringify(state.accessToken));
    localStorage.setItem('_refresh_token', JSON.stringify(state.refreshToken));
    this.router.navigate(['/dashboard']);
    return dispatch([new AuthActions.AutoLogin()]);
  }
}
